import { wrapClientApiCall } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall';

export const refreshData = ({
  globalorder_id,
  locale,
  setRetailRestock,
  setLoading,
  createAlert
}) => {

  const params = {
    globalorder_id,
    boutique: locale
  };

  const caller = wrapClientApiCall({
    url: 'boutiquerestocks/fetch-detail',
  });

  setLoading(true);

  return caller({ 
    params 
  })
    .then(({ boutiquerestock: retailrestock }) => {

      if(retailrestock){
        //temp
        retailrestock.isReceiving = () => false;
        retailrestock.isPreparing = () => false;
        retailrestock.isPrepared = () => false;
        setRetailRestock(retailrestock);
      }
      return;
    })
    .catch(err => createAlert({ message: err.message }))
    .finally(() => setLoading(false));
    
};