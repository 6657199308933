import PropTypes from 'prop-types';

/**
 * 
 * @param {*} param0 
 * @returns 
 */
export const handleBarcodeScan = ({ gopps, setBarcodePath }) =>
  barcode => {
    const gopp = gopps.find(g => g.barcode === barcode);
    if (!gopp) return;
    setBarcodePath(gopp._id);
  };

handleBarcodeScan.propTypes = {
  gopps: PropTypes.arrayOf(PropTypes.object),
  setBarcodePath: PropTypes.func
};