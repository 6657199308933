import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { LoadingSpinner } from '@kelbongoo/shared-client-react-v2/dist/components/LoadingSpinner';
import { RestocksDetailView } from '@kelbongoo/shared-client-react-v2/dist/components/RestocksDetailView';
import { EnsureGlobalOrderWrapper } from '@kelbongoo/shared-client-react-v2/dist/components/SharedPrepaDistribBase';
import { formatItems as formatRedirectionItems } from '@kelbongoo/shared-methods/globalorderredirections/collection';
import { createAlert } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { sortByProps } from '@kelbongoo/shared-methods/utils/sortByProps';
import { run as formatDate } from '@kelbongoo/shared-methods/utils/formatDate';
import { ErrorBoundary } from '../../ui/ErrorBoundary';
import { 
  loadData, 
  addComment as addCommentBase, 
  toggleItemCompleted as toggleItemCompletedBase,
  updateItemQuantity as updateItemQuantityBase,
  updateStatus as updateStatusBase
} from './actions';

const formatItems = redirection => {

  return formatRedirectionItems(
      redirection.items, 
      redirection.producerproducts
    )
    .slice(0)
    .sort((a,b) => {
      if(a.producer_name < b.producer_name) return -1;
      if(a.producer_name > b.producer_name) return 1;
      if(a.product_name < b.product_name) return -1;
      if(a.product_name > b.product_name) return 1;
      return 0;
    });
}

const buildTitle = ({ currentLocale, redirection }) => {
  return `
    ${currentLocale === redirection.locale_source ? '<i>Ici</i>' : redirection.locale_source} 
    <span class='glyphicon glyphicon-arrow-right'></span> 
    ${currentLocale === redirection.locale_destination ? '<i>Ici</i>' : redirection.locale_destination}
  `
}


export const RedirectionsDetailBase = () => {

  const { redirection_id } = useParams();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [redirection, setRedirection] = useState();

  const { currentGlobalOrder: globalorder_id } = useSelector(state => state.globalorderlocales);
  const { currentLocale: locale } = useSelector(state => state.locales);
  const { init } = useSelector(state => state.session);
  const commonParams = {
    globalorder_id,
    redirection_id,
    setRedirection,
    createAlert: arg => dispatch(createAlert(arg)),
  }

  const addComment = addCommentBase({
    ...commonParams,
    redirection
  })
  const toggleItemCompleted = toggleItemCompletedBase({
    ...commonParams,
    redirection
  })
  const updateItemQuantity = updateItemQuantityBase({
    ...commonParams,
    redirection
  })
  const updateStatus = updateStatusBase({
    ...commonParams,
    redirection
  })


  const upperViewParams = {
    title: buildTitle({ currentLocale: locale, redirection }),
    subtitle: redirection.preparedAt
      ? `Préparé ${formatDate(redirection.preparedAt, 'HH:mm')}`
      : 'En attente',
    subsubtitle: redirection.receivedAt
      ? `Reçu ${formatDate(redirection.receivedAt, 'HH:mm')}`
      : '',
    statusName: redirection.getStatusTitle(),
    eventsList: redirection.getEventsList(),
    commentsProps: {
      comments: redirection.comments,
      addComment: addComment,
      allowAddComment: true,
      showComments: true
    }
  }
  const getProgressPct = () => redirection.isPreparing()
    ? redirection.getProgressPct('preparation')
    : redirection.getProgressPct('reception')
  const onValidateChecklist = redirection.locale_source === locale
    ? updateStatus('prepared')
    : updateStatus('received');
  const getRedirectionTotals = () => ({})


  useEffect(() => {

    if(init){
      return;
    }

    loadData({ 
      ...commonParams,
      setLoading
    })
  }, [globalorder_id, redirection_id, init, commonParams]);

  if(loading){

    return <LoadingSpinner />
  }

  return (
    <Container>
      <RestocksDetailView 
        restock={Object.assign(redirection, { items: formatItems(redirection.items) })}
        itemReferenceKey='producerproduct_id'
        formatItems={formatRedirectionItems}
        showUpperView={true}
        allowPreparation={redirection.locale_source === locale}
        allowReception={redirection.locale_destination === locale}
        allowMarkArrived={redirection.locale_destination === locale}
        allowMarkAccepted={redirection.locale_source === locale}
        onMarkArrived={updateStatus('arrived')}
        onMarkAccepted={updateStatus('accepted')}
        onValidateChecklist={onValidateChecklist}
        toggleCompleted={toggleItemCompleted}
        updateQuantity={updateItemQuantity}
        sortItems={sortByProps(['producer_name','product_name'])}
        showChecklistView={redirection.isPreparing() || redirection.isReceiving()}
        getProgressPct={getProgressPct}
        getTotals={getRedirectionTotals}
        upperViewParams={upperViewParams}
      />
    </Container>
  )
}

export const RedirectionsDetail = props => (
	<ErrorBoundary>
		<EnsureGlobalOrderWrapper>
			<RedirectionsDetailBase {...props} />
		</EnsureGlobalOrderWrapper>
	</ErrorBoundary>
)