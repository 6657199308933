import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ChooseGlobalOrder as ChooseGlobalOrderBase, EnsureGlobalOrderWrapper } from '@kelbongoo/shared-client-react-v2/dist/components/SharedPrepaDistribBase';
import { ErrorBoundary } from '../../ui/ErrorBoundary';


export const ChooseGlobalOrder = props => (
	<ErrorBoundary>
    <EnsureGlobalOrderWrapper  currentPath='choose-globalorder'>
		  <ChooseGlobalOrderBase
        Redirect={Redirect} 
        useSelector={useSelector} 
        useDispatch={useDispatch}
        {...props} 
      />
    </EnsureGlobalOrderWrapper>
	</ErrorBoundary>
)