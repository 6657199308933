import { createSlice } from '@reduxjs/toolkit';
import { localesInitialState, localesReducers } from '@kelbongoo/shared-client-react-v2/dist/components/SharedPrepaDistribBase';

const { reducer, actions } = createSlice({
  name: 'locales',
  initialState: localesInitialState,
  reducers: {
    ...localesReducers
  }
});

export { reducer, actions };