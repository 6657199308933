import React, { useEffect } from "react";
import { BrowserRouter, HashRouter, Switch, Route } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";
import { ProtectedRoute } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase";
import { NotFound } from "@kelbongoo/shared-client-react-v2/dist/components/NotFound";
import { ErrorPage } from "@kelbongoo/shared-client-react-v2/dist/components/ErrorPage";
import {
  fetchLocales,
  refreshGlobalOrderLocales,
} from "@kelbongoo/shared-client-react-v2/dist/components/SharedPrepaDistribBase/actions";
import { Login } from "./components/pages/Login";
import { ForgotPassword } from "./components/pages/ForgotPassword";
import { Header } from "./components/ui/Header";
import { Home } from "./components/pages/Home";
import { ChooseGlobalOrder } from "./components/pages/ChooseGlobalOrder";
import { NoGlobalOrders } from "./components/pages/NoGlobalOrders";
import { DispatchHome } from "./components/pages/DispatchHome";
import { DispatchDetail } from "./components/pages/DispatchDetail";
import { ReceptionND } from "./components/pages/ReceptionND";
import { ReceptionRetail } from "./components/pages/ReceptionRetail";
import { RedirectionsHome } from "./components/pages/RedirectionsHome";
import { RedirectionsDetail } from "./components/pages/RedirectionsDetail";
import { Tasks } from "./components/pages/Tasks";
import "./icons";
import "./App.css";
import { fetchLocaleDetail } from "./actions/fetchLocaleDetail";
import { fetchAllGopps } from "./actions/fetchAllGopps";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

const GLOBALORDER_REFRESH_INTERVAL = 5 * 60 * 1000; // 5 minutes

Sentry.init({
  dsn: "https://4cbb7323424fc164639784f1e8f7c259@o4506003755237376.ingest.sentry.io/4506031783477248",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  beforeSend(event) {
    // Modify event to remove non-serializable values
    if (event && event.extra) {
      event.extra = JSON.stringify(event.extra);
    }
    return event;
  }
});

const App = () => {
  const Router = window.cordova ? HashRouter : BrowserRouter;

  const dispatch = useDispatch();
  const store = useStore();
  const { loggedIn, isVerifying } = useSelector((state) => state.currentUser);
  const { currentLocale } = useSelector((state) => state.locales);
  const { currentGlobalOrder } = useSelector((state) => state.globalorderlocales);

  useEffect(() => {
    dispatch(fetchLocales());
    const state = store.getState();
    const style = document.createElement('style');
    state.locales.locales.forEach((locale) => {
      style.innerHTML += `
        .btn-${locale.code.toLowerCase()}, .badge-${locale.code.toLowerCase()} {
          background-color: ${locale.badge_colors?.bg || "#ffffff"};
          color: ${locale.badge_colors?.txt || "#000000"};
        }
        .locale-border-${locale.code.toLowerCase()}{
          border: ${locale.badge_colors?.bg ? "0.4em solid " + locale.badge_colors?.bg : "0.4em solid #000000"};
        }
      `;
    });
    document.head.appendChild(style);
    return () => document.head.removeChild(style);
  }, [dispatch, store]);

  useEffect(() => {
    if (loggedIn && currentLocale && !isVerifying) {
      dispatch(refreshGlobalOrderLocales("preparation"));
      const interval = setInterval(() => {
        dispatch(refreshGlobalOrderLocales("preparation"));
      }, GLOBALORDER_REFRESH_INTERVAL);
      return () => clearInterval(interval);
    }
  }, [loggedIn, currentLocale, isVerifying, dispatch]);

  useEffect(() => {
    if (loggedIn && currentLocale && !isVerifying && currentGlobalOrder) {
      dispatch(fetchLocaleDetail());
      dispatch(fetchAllGopps());
      const state = store.getState();
      const style = document.createElement('style');
      state.locales.locales.forEach((locale) => {
        style.innerHTML += `
          .btn-${locale.code.toLowerCase()}, .badge-${locale.code.toLowerCase()} {
            background-color: ${locale.badge_colors?.bg || "#ffffff"};
            color: ${locale.badge_colors?.txt || "#000000"};
          }
        `;
      });
      document.head.appendChild(style);
      return () => document.head.removeChild(style);
    }
  }, [loggedIn, currentLocale, currentGlobalOrder, isVerifying, dispatch, store]);

  return (
    <Sentry.ErrorBoundary fallback={"Une erreur s'est produite !"}>
      <Router>
        <Header />
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <ProtectedRoute exact path="/" component={Home} />
          <ProtectedRoute exact path="/choose-globalorder" component={ChooseGlobalOrder} />
          <ProtectedRoute exact path="/no-globalorders" component={NoGlobalOrders} />
          <ProtectedRoute exact path="/dispatch/:couloir" component={DispatchHome} />
          <ProtectedRoute exact path="/dispatch/:couloir/:producerproduct_id" component={DispatchDetail} />
          <ProtectedRoute exact path="/reception-nd" component={ReceptionND} />
          <ProtectedRoute exact path="/reception-retail" component={ReceptionRetail} />
          <ProtectedRoute exact path="/redirections" component={RedirectionsHome} />
          <ProtectedRoute exact path="/redirections/:redirection_id" component={RedirectionsDetail} />
          <ProtectedRoute exact path="/tasks" component={Tasks} />
          <ProtectedRoute exact path="/error" component={ErrorPage} />
          <Route exact path="/not-found" component={NotFound} />
        </Switch>
      </Router>
    </Sentry.ErrorBoundary>
  );
};

export default App;
