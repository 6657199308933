import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container';
import { LoadingSpinner } from '@kelbongoo/shared-client-react-v2/dist/components/LoadingSpinner';
import { TaskInstancesChecklist } from '@kelbongoo/shared-client-react-v2/dist/components/TaskInstancesChecklist';
import { createAlert } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { ErrorBoundary } from '../../ui/ErrorBoundary';
import { 
  loadData,
  addComment as addCommentBase,
  toggleCancel as toggleCancelBase,
  toggleMarkDone as toggleMarkDoneBase
} from './actions';


export const TasksBase = () => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [taskinstances, setTaskInstances] = useState([]);
  const { init } = useSelector(state => state.session);
  const { currentLocale: locale } = useSelector(state => state.locales);
  const onValidation = () => {}

  const commonParams = {
    locale,
    taskinstances,
    setTaskInstances,
    createAlert: arg => dispatch(createAlert(arg)),
  }
  const addComment = addCommentBase(commonParams)
  const toggleCancel = toggleCancelBase(commonParams)
  const toggleMarkDone = toggleMarkDoneBase(commonParams)

  useEffect(() => {

    if(init){
      return;
    }

    loadData({ 
      ...commonParams,
      setLoading
    })
  }, [locale, init]);

  if(loading){

    return <LoadingSpinner />
  }

  return (
    <Container fluid>
      <TaskInstancesChecklist
        taskinstances={taskinstances}
        allowValidation={true}
        onValidation={onValidation}
        hideProgress={false}
        addComment={addComment}
        toggleCancel={toggleCancel}
        toggleMarkDone={toggleMarkDone}
      />
    </Container>
  )
}

export const Tasks = props => (
	<ErrorBoundary>
		<TasksBase {...props} />
	</ErrorBoundary>
)