import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import Badge from 'react-bootstrap/Badge';
import { getVariantFromLocale } from '../../../../helpers/getVariantFromLocale';
import { getPositionFromLocale } from '../../../../helpers/getPositionFromLocale';

const sortByDefaultOrder = (locales) => (a, b) => {

  if (getPositionFromLocale(a.locale, locales) === getPositionFromLocale(b.locale, locales)) {
    if (a.number < b.number) {
      return -1;
    }
    if (a.number > b.number) {
      return 1;
    }
  }
  return getPositionFromLocale(b.locale, locales) > getPositionFromLocale(a.locale, locales) ? -1 : 1;
}

export const DispatchDetailHeaderAllDispatchModal = ({ locale_progress, groupeddispatchlocales, locale }) => {
  const _dispatch_queue = locale_progress
    .filter(el => typeof el.dispatch_queue !== "undefined")
    .map(el => {
      let locale_dispatch_queue = JSON.parse(el.dispatch_queue);
      return locale_dispatch_queue.map(ell => {
        return { locale: el.locale, ...ell }
      })
    })
    .reduce((prev, curr) => prev.concat(curr), [])
    .sort(sortByDefaultOrder(groupeddispatchlocales));
  return (
    <ListGroup>
      {_dispatch_queue.map((d, i) => (
        <ListGroupItem key={`dq_${i}`} className='bold text-center'>
          {groupeddispatchlocales.includes(locale) && <Badge className="mx-1 vertical-center" variant={getVariantFromLocale(d.locale)} >{d.locale}</Badge>}
          <span className='text-3xl vertical-center'>{d.number}</span><span className='text-2xl vertical-center'>&nbsp;(&#215;&nbsp;{d.quantity > 0 && `${d.quantity}`})</span>
        </ListGroupItem>
      ))}
    </ListGroup>
  )
}