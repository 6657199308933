import React from 'react';
import { Form } from 'react-bootstrap';

const toSelectValue = (bac) => `${bac.locale}-${bac.number}`

export const DispatchDetailFooterUndispatchModalBac = ({
  bacs = [],
  selectedBac,
  onBacSelection,
}) => (
  <Form.Group>
    <Form.Label htmlFor='number'>Num&eacute;ro de bac</Form.Label>
    <Form.Control
      as='select'
      size='lg'
      id='number'
      name='number'
      value={selectedBac ? toSelectValue(selectedBac) : 'placeholder'}
      onChange={e => {
        const [locale, number] = e.target.value.split('-');
        return onBacSelection({ locale, number: Number(number) });
      } }
    >
      <option disabled value='placeholder'>Choisir un bac</option>
      {bacs.map(b => (
        b.done.map(_ => <option value={toSelectValue(b)}>
          {b.locale}&nbsp;
          {b.number}&nbsp;
          {b.adjustments ? (<span>'*ADJ*'</span>) : ''}
        </option>)
      ))}
    </Form.Control>
  </Form.Group>
)

