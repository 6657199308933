import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";
import { refreshData } from './refreshData';

export const toggleCompleted = ({
  globalorder_id,
  locale,
  producerproduct_id,
  createAlert,
  setCurrentDispatch,
  couloir,
  getGroupedDispatchProducts,
  loadData,
  excludedLocales
}) => (completed) => () => {
  const caller = wrapClientApiCall({
    url: 'globalorders/toggle-golpps-completed',
  })
  return caller({
    params: {
      globalorder_id,
      locale,
      producerproduct_id,
      userId: '2W8X2SaMhytesy4ne',
      completed,
      excludedLocales,
      isND: false
    }
  })
    .then(
      () => refreshData({
        globalorder_id,
        locale,
        producerproduct_id,
        setCurrentDispatch,
        createAlert,
        couloir,
        getGroupedDispatchProducts,
      })()
    )
    .catch((err) => createAlert({ message: err.message }))
    .finally(loadData);
};