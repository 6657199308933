import { wrapClientApiCall } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall';

export const addComment = ({
  taskinstances,
  setTaskInstances,
  createAlert
}) => (taskinstance_id) => (comment, metadata = {}) => {

  const caller = wrapClientApiCall({
    url: 'taskinstances/add-comment',
  });

  return caller({
    params: {
      taskinstance_id, 
      comment
    }
  })
    .then(({ comments }) => {
      const index = taskinstances.findIndex(
        t => t._id === taskinstance_id
      )
      taskinstances[index].comments = comments;
      
      setTaskInstances(taskinstances);
    })
    .catch(err => createAlert({ message: err.message }));
};