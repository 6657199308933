import { wrapClientApiCall } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall';


export const addComment = ({
  redirection,
  setRedirection,
  createAlert
}) => comment => {

  const caller = wrapClientApiCall({
    url: 'globalorderredirections/add-comment',
  })
  return caller({
    params: {
      redirection_id: redirection._id,
      comment
    }
  })
    .then(({ comments }) => {

      redirection.comments = comments;
      setRedirection(redirection);
    })
    .catch(err => createAlert({ message: err.message }));
};