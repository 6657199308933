import React, { useState } from 'react';
import { CommentsListModal } from '@kelbongoo/shared-client-react-v2/dist/components/CommentsListModal';
import { Checkbox } from '@kelbongoo/shared-client-react-v2/dist/components/Checkbox';
import { TouchActivatedInventory } from '@kelbongoo/shared-client-react-v2/dist/components/TouchActivatedInventory';

export const ReceptionNDRow = ({ item }) => {

  const {
    ref_id,
    producerproduct_id,
    producer_name,
    product_name,
    unit_display,
    comments = [],
    addComment,
    allowAddComment,
    locale_quantity_expected,
    locale_quantity_received,
    updateItemQuantity,
    locale_progress,
    toggleCompleted
  } = item;

  const completed = locale_progress.reduce((p, c) => p && c.completed, true);
  const localeQuantity = locale_quantity_received !== undefined ? locale_quantity_received : locale_quantity_expected;
  const [quantity, setQuantity] = useState(localeQuantity);

  const onUpdateQuantity = quantity => {
    setQuantity(quantity);
    updateItemQuantity(quantity);
  }

  return (
    <tr key={producerproduct_id}>
      <td style={{ padding: 0 }}>
        <table style={{ width: '100%', border: 'none' }}>
          <tbody>
            <tr>
              <td style={{ width: '50px', padding: '0.75rem 0', border: 'none' }}>
                <CommentsListModal
                  comments={comments}
                  addComment={addComment(ref_id)}
                  allowAddComment={allowAddComment}
                  triggerName='icon'
                />
              </td>
              <td style={{ border: 'none' }}>
                <div>
                  <b>{product_name}</b>
                </div>
                <div className='italic text-sm'>{producer_name}</div>
                <div className='italic text-sm text-muted'>{unit_display}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
      <td className='text-center vertical-center' style={{ width: '120px', verticalAlign: 'middle' }}>
        <TouchActivatedInventory
          onUpdate={onUpdateQuantity}
          disabled={!!completed}
          value={quantity}
          showExpected={locale_quantity_expected !== quantity}
          expectedValue={locale_quantity_expected}
          timeout={800}
        />
      </td>
      <td className='text-center vertical-center' style={{ width: '70px', verticalAlign: 'middle' }}>
        <Checkbox
          onToggleChecked={toggleCompleted}
          value={!!completed}
        />
      </td>
    </tr>
  )
}