import { library } from '@fortawesome/fontawesome-svg-core'

import { 
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowDown,
  faBullhorn,
  faCarrot,
  faCheck,
  faCheckCircle,
  faClock,
  faCog,
  faComment,
  faEuroSign,
  faExchangeAlt,
  faExclamationCircle,
  faHome,
  faList,
  faSignInAlt,
  faSignOutAlt,
  faSnowflake,
  faTasks,
  faTimes,
  faUser,
  faMinus,
  faThumbsUp,
  faBarcode,
  faWrench
} from '@fortawesome/free-solid-svg-icons';

import { 
  faClock as farClock
} from '@fortawesome/free-regular-svg-icons';

library.add(
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowDown,
  faBullhorn,
  faCarrot,
  faCheck, 
  faCheckCircle,
  faClock,
  faCog,
  faComment,
  faEuroSign,
  faExchangeAlt,
  faExclamationCircle,
  faHome,
  faList,
  faMinus,
  faSignInAlt, 
  faSignOutAlt, 
  faSnowflake,
  faTasks,
  faThumbsUp,
  faTimes,
  faUser, 
  farClock,
  faBarcode,
  faWrench
);