import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { ModalBase } from '@kelbongoo/shared-client-react-v2/dist/components/ModalBase';
import { Icon } from '@kelbongoo/shared-client-react-v2/dist/components/Icon';
import { DispatchDetailFooterUndispatchModal } from './DispatchDetailFooterUndispatchModal';
import { blurAll } from '../../../../helpers/blurAll';

export const DispatchDetailFooter = ({
  currentStartNumber,
  currentLimit,
  reverseDispatchPath,
  setCurrentStartNumber,
  setCurrentLimit,
  toggleReverseDispatchPath,
  bacs,
  undispatchOne,
  undispatchAll,
  completed,
}) => {
  const [showMenu, setShowMenu] = useState(true);
  const [showUndispatchModal, setShowUndispatchModal] = useState(false);

  const closeFooterMenu = () => setShowMenu(false);
  const openFooterMenu = () => setShowMenu(true);
  const openUndispatchModal = () => setShowUndispatchModal(true);
  const closeUndispatchModal = () => setShowUndispatchModal(false);

  const handleChangeStartNumber = e => setCurrentStartNumber(e.target.value);
  const handleChangeCurrentLimit = e => setCurrentLimit(e.target.value);

  let handleAction = (action) => {
    blurAll();
    return action;
  }

  return (<>
    <footer className='footer-menu'>
      <Row>
        <Col xs={12} className={showMenu ? 'footer-menu-show' : ''} style={{ padding: '5px 0' }}>
          {showMenu ? (
            <div style={{ width: '20%', display: 'inline-block', padding: '5px 10px', boxSizing: 'border-box' }}>
              <Button
                variant='outline-secondary'
                size='lg'
                block
                onClick={handleAction(closeFooterMenu)}
              >
                <Icon name='arrow-right' />
              </Button>
            </div>
          ) : (
            <div className='float-right' style={{ width: '20%' }}>
              <Button
                variant='outline-secondary'
                size='lg'
                block
                onClick={handleAction(openFooterMenu)}
              >
                <Icon name='arrow-left' />
              </Button>
            </div>
          )}
          <div className='footer-menu-hidden' style={{ width: '20%' }}>
            {completed ?
              <OverlayTrigger overlay={<Tooltip>Vous ne pouvez d&eacute;-dispatcher que les produits non terminés</Tooltip>}>
                <span>
                  <Button
                    variant='danger'
                    size='lg'
                    block
                    onClick={handleAction(openUndispatchModal)}
                    disabled
                    style={{ pointerEvents: 'none' }}
                  >
                    <Icon name='times' />
                  </Button>
                </span>
              </OverlayTrigger>
              :
              <Button
                variant='danger'
                size='lg'
                block
                onClick={handleAction(openUndispatchModal)}
              >
                <Icon name='times' />
              </Button>
            }
          </div>
          <div className='footer-menu-hidden' style={{ width: '20%' }}>
            <Button
              variant={reverseDispatchPath ? 'outline-secondary' : 'warning'}
              size='lg'
              block
              onClick={toggleReverseDispatchPath}
            >
              <Icon name={reverseDispatchPath ? 'arrow-down' : 'arrow-up'} />
            </Button>
          </div>
          <div className='footer-menu-hidden' style={{ width: '20%' }}>
            <Form.Control
              size='lg'
              className='text-center'
              id='start_number'
              type='number'
              value={currentStartNumber}
              onChange={handleChangeStartNumber}
            />
          </div>
          <div className='footer-menu-hidden' style={{ width: '20%' }}>
            <Form.Control
              as='select'
              size='lg'
              className='text-center'
              id='limit'
              value={currentLimit}
              onChange={handleAction(handleChangeCurrentLimit)}
            >
              <option value='3'>3</option>
              <option value='4'>4</option>
              <option value='5'>5</option>
              <option value='6'>6</option>
              <option value='7'>7</option>
              <option value='8'>8</option>
              <option value='9'>9</option>
              <option value='10'>10</option>
              <option value={bacs.length}>Tous les bacs</option>
            </Form.Control>
          </div>
        </Col>
      </Row>
    </footer>

    <ModalBase modalProps={{ show: showUndispatchModal, onHide: handleAction(closeUndispatchModal) }}>
      <DispatchDetailFooterUndispatchModal
        bacs={bacs}
        undispatchOne={undispatchOne}
        undispatchAll={undispatchAll}
        allowValidation={true}
        closeModal={handleAction(closeUndispatchModal)}
      />
    </ModalBase>
  </>);
};