import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const addBacComment = ({ 
  globalorder_id, 
  locale, 
  couloir,
  orderComments,
  setOrderComments,
  createAlert,
  loadData
}) => (bac) => (comment, isPhoto = false) => {

  const caller = wrapClientApiCall({
    url: 'orders/add-bac-comment'
  })

  return caller({
    params: {
      globalorder_id,
      locale,
      couloir,
      bac,
      comment,
      isPhoto,
      userId: '2W8X2SaMhytesy4ne'
    },
  })
    .then(({ order_id, comments, bacs }) => {

      let ind = orderComments.findIndex(
        (o) => o.order_id === order_id
      );

      if (ind > -1) {

        orderComments[ind].comments = comments;

      } else {

        orderComments.push({
          order_id,
          comments,
          bacs,
        });
      }

      setOrderComments(orderComments);
      loadData();
    })
    .catch((err) => {
      createAlert({ message: err.message })
    });
};