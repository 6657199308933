import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import { ValidationButton } from '@kelbongoo/shared-client-react-v2/dist/components/ValidationButton';
import { Icon } from '@kelbongoo/shared-client-react-v2/dist/components/Icon';
import { DispatchDetailFooterUndispatchModalBac } from './DispatchDetailFooterUndispatchModalBac';

export const DispatchDetailFooterUndispatchModal = ({
  bacs,
  running,
  undispatchOne,
  undispatchAll,
  closeModal
}) => {
  const [selectedType, setSelectedType] = useState('bac')
  const [selectedBac, setSelectedBac] = useState();

  if (!bacs) {
    return (
      <div className='text-center mt-4'>
        <Icon name='minus-circle' />
        <p className='text-muted mt-4'>Aucun bac dispatch&eacute;</p>
      </div>
    )
  }

  const undispatchableBacs = bacs.filter(b => b.done.length > 0)
  const onValidation = () => {
    if (selectedType === 'bac') {
      let bac = bacs.find(b => b.number === selectedBac.number && b.locale === selectedBac.locale);
      undispatchOne(bac.done[0], bac.locale).finally(() => closeModal());
    } else if (selectedType === 'all') {
      undispatchAll().finally(() => closeModal());
    } else {
      closeModal();
    }
  }

  const allowValidation = selectedType === 'all'
    || selectedBac

  return (
    <>
      <Row className='mb-4'>
        <Col className='justify-content-between fs-3 text-center'>
          <ToggleButtonGroup name='undo-dispatch-type' value={selectedType} onChange={setSelectedType}>
            <ToggleButton variant='outline-secondary' value='bac'>Un bac</ToggleButton>
            <ToggleButton variant='outline-secondary' value='all'>Tous les bacs</ToggleButton>
          </ToggleButtonGroup>
        </Col>
      </Row>

      <div className='mb-4'>
        {selectedType === 'bac' && (
          <DispatchDetailFooterUndispatchModalBac
            bacs={undispatchableBacs}
            selectedBac={selectedBac}
            onBacSelection={bac => setSelectedBac(bac)}
          />
        )}
      </div>

      {allowValidation && (
        <ValidationButton
          loading={running}
          block
          variant='primary'
          onValidation={onValidation}
        />
      )}
    </>
  )
}