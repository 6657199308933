import { wrapClientApiCall } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall';
import { actions as productInfosActions } from '../reducers/productinfos';
import { actions as groupeddispatchlocalesActions } from '../reducers/groupeddispatchlocales';

export const fetchLocaleDetail = () => (dispatch, getState) => {

  const {
    locales: { currentLocale: locale },
    globalorderlocales: { currentGlobalOrder: globalorder_id }
  } = getState();
  const {
    fetchProductInfosRequest,
    fetchProductInfosSuccess,
    fetchProductInfosFailed
  } = productInfosActions;

  const {
    fetchgroupeddispatchlocalesRequest,
    fetchgroupeddispatchlocalesSuccess,
    fetchgroupeddispatchlocalesFailed
  } = groupeddispatchlocalesActions;

  const caller = wrapClientApiCall({
    url: 'globalorders/fetch-locale-detail'
  });

  dispatch(fetchProductInfosRequest());
  dispatch(fetchgroupeddispatchlocalesRequest());

  return caller({
    params: {
      locale,
      globalorder_id
    }
  })
    .then(({ globalorder, globalorderlocale, productInfos = [] }) => {
      dispatch(fetchProductInfosSuccess(productInfos));
      dispatch(fetchgroupeddispatchlocalesSuccess(globalorder.grouped_dispatch_locales))
    })
    .catch(error => {
      dispatch(fetchProductInfosFailed(error.message || error.name))
      dispatch(fetchgroupeddispatchlocalesFailed(error.message || error.name))
    });
};