import React, { useState } from 'react';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import { ButtonSubmit } from '@kelbongoo/shared-client-react-v2/dist/components/ButtonSubmit';
import { useFormHandler } from '@kelbongoo/shared-client-react-v2/dist/utils/CustomHooks/useFormHandler';
import { onValidation } from './onValidation';
import { InputGroup } from 'react-bootstrap';



export const DispatchDetailBacRightModal = ({
  expectedWeight,
  adjustment,
  undoAdjustWeight,
  otherBacs,
  adjustDispatchBac,
  adjustDispatchWeight,
  closeModal
}) => {

  const [mainView, setMainView] = useState('bac')
  const [running, setRunning] = useState(false);
  
  const isUndoWeightAdjustment = expectedWeight && mainView === 'weight' && adjustment;
  const isWeightAdjustment = expectedWeight && mainView === 'weight' && !adjustment;
  const isAdjustBac = mainView === 'bac';

  const {
    handleSubmit,
    handleInputChange,
    inputs,
    clearInputs
  } = useFormHandler(
    onValidation({ 
      undoAdjustWeight, 
      adjustDispatchBac,
      adjustDispatchWeight, 
      setRunning,
      closeModal
    })
  )

  const handleSelect = view => {
    clearInputs(); // clear form inputs
    setMainView(view)
  }
  const onSubmit = e => handleSubmit(e, {
    isUndoWeightAdjustment, 
    isAdjustBac, 
    isWeightAdjustment
  })

  const allowValidation = isUndoWeightAdjustment 
    || (isWeightAdjustment && inputs.weight && inputs.weight < expectedWeight) 
    || (isAdjustBac && typeof inputs.bac !== 'undefined');

  return (
    <div className='mb-4'>

      {expectedWeight && (
        <div className='mb-4'>
          <ToggleButtonGroup 
            name='select-view'
            size='lg' 
            style={{width : '100%'}} 
            data-testid='display-button-toggle' 
            onChange={handleSelect}
            value={mainView}
          >
            <ToggleButton value='bac' variant='outline-secondary'>Ajuster le bac</ToggleButton>
            <ToggleButton value='weight' variant='outline-secondary'>Ajuster le poids</ToggleButton>
          </ToggleButtonGroup>
        </div>
      )}

      <Form onSubmit={onSubmit}>
        {isWeightAdjustment && (
          <Form.Group>
          <Alert variant='info'>Le nouveau poids doit être inférieur à l'ancien.</Alert>
            <Table bordered>
              <thead>
                <tr>
                  <th className='text-center'>Poids attendu</th>
                  <th className='text-center'>Poids r&eacute;el</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='text-center text-2xl vertical-center'>{expectedWeight} kg</td>
                  <td className='text-center'>
                    <InputGroup>
                      <Form.Control 
                        data-testid='adjust-weight-input'
                        type='number' 
                        size='lg'
                        className='text-center' 
                        id='weight' 
                        name='weight' 
                        step='0.01'
                        placeholder='Poids réél'
                        value={inputs.weight}
                        onChange={handleInputChange}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text>kg</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Form.Group>
        )}
        {isAdjustBac && (
          <Form.Group>
            <Form.Label htmlFor='bac'>Dispatcher dans un autre bac</Form.Label>
            <Form.Control 
              as='select' 
              size='lg' 
              id='bac'
              name='bac'
              data-testid='adjust-dispatch-bac-select'
              onChange={handleInputChange}
              value={inputs.bac || 'placeholder'}
            >
              <option disabled value='placeholder'>Choisir un bac</option>
              {otherBacs.map((o,i) => (
                <option key={i} value={o}>{o}</option>
              ))}
              <option value='0'>+ Ajouter un bac</option>
            </Form.Control>
          </Form.Group>
        )}
        <Form.Group>
          <ButtonSubmit
            buttonText={isUndoWeightAdjustment ? 'Annuler l\'ajustement de poids' : 'Valider'}
            buttonIcon={ isUndoWeightAdjustment ? 'times' : 'check'}
            buttonProps={{ 
              size: 'lg', 
              block: true, 
              variant: isUndoWeightAdjustment ? 'warning' : 'primary', 
              'data-testid': `submit-${isUndoWeightAdjustment ? 'undo' : isWeightAdjustment ? 'weight' : 'bac'}`
            }}
            enabled={allowValidation}
            loading={running}
          />
        </Form.Group>
      </Form>
    </div>
  )
}