import React from 'react';
import { run as formatDate } from '@kelbongoo/shared-methods/utils/formatDate';
import selectors from '../../../selectors';

export const SelectedGlobalDate = () => {
  const globalorderlocale = selectors.getCurrentGlobalOrderLocale();

  return globalorderlocale?.distributions?.length > 0 && (
    <div
      data-testid="current-distribution"
      style={{ display: 'inline-block', marginLeft: '1em' }}
    >
      Globale du&nbsp;
      <b>{formatDate(globalorderlocale.distributions[0].start, 'DD/MM')}</b>
    </div>
  );
};
