import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { CommentsListModal } from '@kelbongoo/shared-client-react-v2/dist/components/CommentsListModal';
import { ValidationButton } from '@kelbongoo/shared-client-react-v2/dist/components/ValidationButton';
import { Icon } from '@kelbongoo/shared-client-react-v2/dist/components/Icon';

export const DispatchHomeRow = ({ item }) => {
  const {
    ref_id,
    producerproduct_id,
    missing,
    couloirName,
    onToggleCompleted,
    producer_name,
    product_name,
    unit_display,
    locale_quantity_expected,
    comments = [],
    isCordova,
    addComment,
    uploadCommentImage,
    getPicture,
    userId,
    dispatch_done,
    completed,
    progressPct
  } = item;


  return (
    <tr className={missing ? 'warning' : ''} key={producerproduct_id}>
      <td style={{ padding: '0' }}>
        <table style={{ width: '100%', border: 'none', margin: '0.75rem 0' }}>
          <tbody>
            <tr>
              <td style={{ padding: '0.1rem', width: '66px', verticalAlign: 'top', border: 'none' }}>
                <CommentsListModal
                  comments={comments}
                  isMobile={isCordova}
                  allowVoiceComment={true}
                  allowValidation={true}
                  addComment={addComment(ref_id)}
                  allowPhotos={true}
                  uploadCommentImage={uploadCommentImage}
                  getPicture={getPicture}
                  hideAddComment={false}
                  allowAddComment={true}
                  triggerName='icon'
                />
              </td>
              <td style={{ padding: '0.1rem', border: 'none' }}>
                <Link to={`/dispatch/${couloirName}/${producerproduct_id}`} style={{ display: 'inline-block', textDecoration: 'none', color: 'inherit' }}>
                  <b>{product_name}</b>
                  <div className='italic fs-1'>{producer_name}</div>
                  <div className='italic fs-1 text-muted'>{unit_display}</div>
                </Link>
              </td>
              {userId && (
                <td style={{ padding: '0.1rem', verticalAlign: 'middle', border: 'none' }}>
                  <Icon name='sync-alt' />
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </td>
      <td className='text-center' style={{ width: '200px', verticalAlign: 'middle' }}>
        <div className='text-center fs-3'>
          {dispatch_done}
          &nbsp;/&nbsp;
          {locale_quantity_expected}
        </div>
        <ProgressBar
          style={{ marginBottom: '0' }}
          now={progressPct}
          variant={completed ? 'success' : ''}
        />
      </td>
      <td className='text-center' style={{ verticalAlign: 'middle' }}>
        <ValidationButton
          buttonProps={{ variant: 'link', size: 'lg' }}
          buttonIcon={completed ? 'check-circle' : 'clock'}
          noButtonText={true}
          buttonClasses={completed ? 'text-success' : 'text-muted'}
          onValidation={onToggleCompleted}
        />
      </td>
    </tr>
  )
};

DispatchHomeRow.propTypes = {
  item: PropTypes.object
};