import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { take } from 'lodash';
import { LoadingSpinner } from '@kelbongoo/shared-client-react-v2/dist/components/LoadingSpinner';
import { createAlert } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';

import { EnsureGlobalOrderWrapper } from '@kelbongoo/shared-client-react-v2/dist/components/SharedPrepaDistribBase';
import { DispatchDetailHeader } from './DispatchDetailHeader';
import { DispatchDetailFooter } from './DispatchDetailFooter';
import { DispatchDetailDone } from './DispatchDetailDone';
import { DispatchDetailBac } from './DispatchDetailBac';
import {
  loadData as loadDataBase,
  dispatchOne as dispatchOneBase,
  dispatchOneFromScan as dispatchOneFromScanBase,
  addBacComment as addBacCommentBase,
  addGoppComment as addGoppCommentBase,
  undoAdjustDispatchWeight as undoAdjustDispatchWeightBase,
  adjustDispatchWeight as adjustDispatchWeightBase,
  adjustDispatchBac as adjustDispatchBacBase,
  toggleCompleted as toggleCompletedBase,
  undispatchOne as undispatchOneBase,
  undispatchAll as undispatchAllBase
} from './actions';

import { updateQuantity as updateQuantityBase } from '../ReceptionND/actions/updateQuantity';

import { formatBacs } from './helpers';
import './index.css';

import { useBarcodeScanListener } from '@kelbongoo/shared-client-react-v2/dist/utils/CustomHooks/useBarcodeScanListener'
import { isCompleted } from '../../../helpers/isCompleted';

const DEFAULT_MAX_BACS = 5;
const DEFAULT_START_NUMBER = 1;

function getDispatchProgress(currentDispatch) {
  if (currentDispatch.length==0 || !currentDispatch) {
    return { done: 0, todo: 0 }
  }
  const sum = (acc, curr) => acc + curr;

  const mappedDispatches = currentDispatch
    .map(d => ({ done: d.done.length, todo: d.items.length }));
  return {
    done: mappedDispatches
      .map(d => d.done)
      .reduce(sum),
    todo: mappedDispatches
      .map(d => d.todo + d.done)
      .reduce(sum, 0)
  };
}

export const DispatchDetailBase = () => {

  const { couloir, producerproduct_id } = useParams();

  const isMobile = !!window.cordova;

  const dispatch = useDispatch();
  const { currentGlobalOrder: globalorder_id } = useSelector(state => state.globalorderlocales);
  const { currentLocale: locale } = useSelector(state => state.locales);
  const { gopps } = useSelector(state => state.gopps);
  const { groupeddispatchlocales } = useSelector(state => state.groupeddispatchlocales);

  const [currentDispatch, setCurrentDispatch] = useState([]);

  const [gopp, setGopp] = useState({});

  const [loading, setLoading] = useState(true);
  const [bacsDone, setBacsDone] = useState([]);
  const [currentLimit, setCurrentLimit] = useState(DEFAULT_MAX_BACS);
  const [currentStartNumber, setCurrentStartNumber] = useState(DEFAULT_START_NUMBER);
  const [reverseDispatchPath, setReverseDispatchPath] = useState(false);
  const [orderComments, setOrderComments] = useState([]);

  const [completed, setCompleted] = useState(gopp.completed);
  const customSetGopp = (gopp) => {
    let new_gopp = {...gopp, completed: isCompleted(false, gopp)}
    console.log("customSetGopp", new_gopp)
    return setGopp(new_gopp)
  }
  const commonParams = {
    bacsDone,
    couloir,
    createAlert: arg => dispatch(createAlert(arg)),
    currentDispatch,
    globalorder_id,
    gopp,
    locale,
    orderComments,
    producerproduct_id,
    setBacsDone,
    setCurrentDispatch,
    setOrderComments,
    setGopp: customSetGopp,
    gopps,
    getGroupedDispatchProducts: groupeddispatchlocales.includes(locale),
  };

  const addGoppComment = addGoppCommentBase(commonParams)

  const loadData = (setLoadingExternal) => () => {
    loadDataBase({
      ...commonParams,
      setLoading: setLoadingExternal,
    })
  }

  const dispatchOne = dispatchOneBase(commonParams)
  const dispatchOneFromScan = dispatchOneFromScanBase({
    ...commonParams, loadData: loadData(function foo() { // this is intentional 
    })
  });

  const addBacComment = addBacCommentBase({
    ...commonParams, loadData: loadData(function foo() { // this is intentional 
    })
  })
  const undoAdjustDispatchWeight = undoAdjustDispatchWeightBase(commonParams)
  const adjustDispatchWeight = adjustDispatchWeightBase({
    ...commonParams, loadData: loadData(function foo() { // this is intentional 
    })
  })
  const adjustDispatchBac = adjustDispatchBacBase({
    ...commonParams, loadData: loadData(function foo() {
      // this is intentional 
    })
  })
  const undispatchOne = undispatchOneBase(commonParams)
  const undispatchAll = undispatchAllBase(commonParams)
  const toggleCompleted = toggleCompletedBase({
    ...commonParams, loadData: loadData(function foo() { // this is intentional 
    })
  })

  const updateQuantity = (locale) =>
    (quantity) => {
      updateQuantityBase({
        refreshData: () => { },
        globalorder_id: commonParams.globalorder_id,
        locale,
        createAlert: commonParams.createAlert
      })(commonParams.producerproduct_id)(quantity)
    }

    const displayBacs = (dispatches) => {
      const groupedByLocale = dispatches.reduce((acc, dispatch) => {
        console.log(dispatch)
        if (!acc[dispatch.locale]) {
          acc[dispatch.locale] = [];
        }
        acc[dispatch.locale].push(dispatch);
        return acc;
      }, {});
    
      return (
        <div>
          {Object.keys(groupedByLocale).map((locale) => (
            <div key={locale} className={`locale-container locale-border-${locale.toLowerCase()}`}>
              <span class="locale-title text-2xl align-middle m-2"style={{backgroundColor: couloir === "sec" ? "#daebde" : "#cee5ff"}}>
                <span data-testid="bac-locale" class={`bold badge badge-${locale.toLowerCase()}`}>{locale}</span>
                </span>
              {groupedByLocale[locale].map((b, index) => (
                <div key={index} className="dispatch-container">
                  <DispatchDetailBac
                    {...b}
                    showComments={true}
                    allowAddComment={true}
                    isMobile={isMobile}
                    locales={groupeddispatchlocales}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      );
    };
    
    

  useEffect(() => {
    if (!globalorder_id || groupeddispatchlocales.length <= 0 || !locale) {
      return;
    }
    loadData(setLoading)()
  }, [groupeddispatchlocales, gopps, locale, globalorder_id]);

  const callback = barcode => dispatchOneFromScan(barcode);

  const barcodeScanEventEmitter = useBarcodeScanListener();

  useEffect(() => {
    barcodeScanEventEmitter.on('onbarcodescanned', callback);
    return () => {
      barcodeScanEventEmitter.off('onbarcodescanned', callback);
    };
  }, [globalorder_id, producerproduct_id, currentDispatch]);


  if (loading) {
    return <LoadingSpinner />
  }

  const allDispatches = formatBacs({
    ...commonParams,
    reverseDispatchPath,
    currentStartNumber,
    currentLimit,
    dispatchOne,
    addBacComment,
    undoAdjustDispatchWeight,
    adjustDispatchWeight,
    adjustDispatchBac,
    updateQuantity,
    locales: groupeddispatchlocales,
    loadData: loadData(function foo() {
    })
  });

  const dispatches = take(allDispatches, Number(currentLimit));
  const { done, todo } = getDispatchProgress(currentDispatch);

  return (
    <Container fluid className={`dispatch-home-${couloir}`}>
      <DispatchDetailHeader
        recentlyDispatchedBacs={bacsDone.slice(-5)}
        progress={done / todo * 100}
        couloirName={couloir}
        addComment={addGoppComment}
        toggleCompleted={toggleCompleted}
        groupeddispatchlocales={groupeddispatchlocales}
        locale={locale}
        completed={completed}
        setCompleted={setCompleted}
        {...gopp}
      />

      <div style={{ marginBottom: '5em' }}>
        {dispatches.length === 0 ? (
          <DispatchDetailDone />
        ) : (
          <>
            {displayBacs(dispatches)}
          </>
        )}
      </div>

      <DispatchDetailFooter
        setCurrentLimit={setCurrentLimit}
        setCurrentStartNumber={setCurrentStartNumber}
        toggleReverseDispatchPath={() => setReverseDispatchPath(!reverseDispatchPath)}
        reverseDispatchPath={reverseDispatchPath}
        currentLimit={currentLimit}
        currentStartNumber={currentStartNumber}
        bacs={currentDispatch}
        undispatchOne={undispatchOne}
        undispatchAll={undispatchAll}
        completed={completed}
        setCompleted={setCompleted}
        {...gopp}
      />
    </Container>
  )
}

export const DispatchDetail = props => (
  <EnsureGlobalOrderWrapper>
    <DispatchDetailBase {...props} />
  </EnsureGlobalOrderWrapper>
)