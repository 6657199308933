import { wrapClientApiCall } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall';

export const loadData = ({
  globalorder_id,
  redirection_id,
  setRedirection,
  createAlert,
  setLoading
}) => {

  const caller = wrapClientApiCall({
    url: 'globalorderredirections/fetch-detail',
  })

  return caller({
    params: {
      globalorder_id,
      redirection_id
    }
  })
    .then(({ globalorderredirection: redirection }) => {

      setRedirection(redirection);
    })
    .catch(err => createAlert(err.message))
    .finally(() => setLoading(false));
};