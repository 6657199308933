import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";
import { transferDispatch } from '../helpers';

export const dispatchOne = ({ 
  globalorder_id, 
  producerproduct_id,
  gopp,
  currentDispatch,
  bacsDone,
  setGopp,
  setCurrentDispatch,
  setBacsDone,
  createAlert
}) => (dispatch_id, locale) => {

  // anticipate success...
  let result = transferDispatch({
    currentDispatch,
    gopp,
    dispatch_id,
    bacsDone,
  });
  
  if(result.gopp.completed) result.gopp.locale_progress.map(v => v.completed = result.gopp.completed);
  setGopp(result.gopp);
  setCurrentDispatch(result.currentDispatch);
  setBacsDone(result.bacsDone);

  const caller = wrapClientApiCall({
    url: 'globalorderlocaleproducerproducts/dispatch-one',
  })

  return caller({
    params: {
      dispatch_id,
      globalorder_id,
      locale,
      producerproduct_id,
      userId: '2W8X2SaMhytesy4ne'
    },
  })
    .catch((error) => {

      let undoResult = transferDispatch({
        currentDispatch: result.currentDispatch,
        gopp: result.gopp,
        dispatch_id,
        undo: true,
        bacsDone: result.bacsDone,
      });
      setGopp(undoResult.gopp);
      setCurrentDispatch(undoResult.currentDispatch);
      setBacsDone(undoResult.bacsDone);
      createAlert({ message: error.message });
    });
};