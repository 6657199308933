import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

// Ensure a value is non-negative
function ensureNonNegative(value) {
  return value < 0 ? 0 : value;
}

// Process item progress and calculate totals
function processItemProgress(item, total) {
  total[item.producerproduct_id] = 0;
  item.locale_progress.forEach((progress) => {
    progress.is_nd = !progress.hasOwnProperty("dispatch_done");
    progress.dispatch_done = progress.is_nd ? 0 : ensureNonNegative(progress.dispatch_done);
    if (!progress.is_nd) {
      total[item.producerproduct_id] += ensureNonNegative(progress.quantity_expected);
    }
  });

  const completed = item.locale_progress.every((progress) => progress.completed);
  const dispatch_done = item.locale_progress.reduce((sum, progress) => sum + progress.dispatch_done, 0);

  return {
    ...item,
    completed,
    dispatch_done,
    progressPct: Math.ceil((dispatch_done / total[item.producerproduct_id]) * 100),
    locale_quantity_expected: total[item.producerproduct_id],
  };
}

// Format items for display
function formatItems(items, gopps, couloir, toggleCompleted, addComment) {
  const total = {};

  const formattedItems = items.map((item) => {
    const processedItem = processItemProgress(item, total);

    const gopp = gopps.find((g) => g.producerproduct_id === item.producerproduct_id);

    return {
      ref_id: item._id,
      ...processedItem,
      ...(gopp || {}),
      onToggleCompleted: () => toggleCompleted(item.producerproduct_id, !processedItem.completed)(),
      couloirName: couloir,
      addComment,
    };
  });

  // Filter out items with total quantity expected of 0
  return formattedItems.filter((item) => item.locale_quantity_expected > 0);
}

export const refreshItems = ({
  globalorder_id,
  page,
  locale,
  couloir,
  currentQuery = {},
  setItems,
  setCount,
  setLoading,
  gopps = [],
  createAlert,
  docsPerPage,
  addComment,
  toggleCompleted,
  getGroupedDispatchProducts,
  excludedLocales,
}) => {
  const params = {
    globalorder_id,
    locale,
    dispatch_done: { $exists: true },
    couloir: couloir === 'frais' ? 0 : 1,
    filterUnsold: true,
    getGroupedDispatchProducts,
    excludedLocales,
    ...currentQuery,
  };

  const caller = wrapClientApiCall({
    url: 'globalorders/fetch-locale-products-list',
  });

  setLoading(true);

  return caller({
    params,
    options: { page, limit: docsPerPage || 10, sort: { product_name: 1 } },
  })
    .then(({ items, count }) => {
      const formattedItems = formatItems(items, gopps, couloir, toggleCompleted, addComment);
      setItems(formattedItems);
      setCount(count);
      setLoading(false);
    })
    .catch((error) => {
      createAlert({ message: error.message });
      setLoading(false);
      return error;
    });
};
