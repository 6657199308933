import { wrapClientApiCall } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall';

export const toggleItemCompleted = ({
  redirection,
  createAlert,
  setRedirection
}) => ({ producerproduct_id, completed, quantity }) => {

  const caller = wrapClientApiCall({
    url: 'globalorderredirections/update-item',
  })

  return caller({
    params: {
      redirection_id: redirection._id,
      producerproduct_id,
      quantity,
      completed
    }
  })
    .then(({ updatedDoc }) => {

      const ind = redirection.items.findIndex(
        i => i.producerproduct_id === producerproduct_id
      );
      let updatedInd = updatedDoc.items.findIndex(
        i => i.producerproduct_id === producerproduct_id
      );
      redirection.items[ind] = updatedDoc.items[updatedInd];
      return setRedirection(redirection);
    })
    .catch(err => createAlert({ message: err.message }));
};