import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const addComment = ({
  createAlert,
  refreshData,
}) => (gopp_id) => (comment, isPhoto = false) => {

  const caller = wrapClientApiCall({
    url: 'globalorderproducerproducts/add-comment',
  })
  
  return caller({
    params: { 
      reference_id: gopp_id, 
      context: 'preparations',
      comment, 
      metadata: { isPhoto },
      userId: '2W8X2SaMhytesy4ne'
    }
  })
    .then(() => refreshData())
    .catch((err) => createAlert({ message: err.message }));
};