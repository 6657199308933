import React from 'react';
import { ModalLink } from '@kelbongoo/shared-client-react-v2/dist/components/ModalLink';
import { CommentsListModal } from '@kelbongoo/shared-client-react-v2/dist/components/CommentsListModal';
import { DispatchDetailBacRightModal } from './DispatchDetailBacRightModal';

export const DispatchDetailBacRight = ({
  expected_weight,
  adjustment,
  undoAdjustWeight,
  otherBacs,
  adjustDispatchBac,
  adjustDispatchWeight,
  showComments,
  comments,
  addComment,
  uploadCommentImage,
  getPicture,
  allowAddComment,
  isMobile,
}) => {

  return (
    <div title='dispatch-detail-bac-right' style={{ position: 'absolute', right: '0', top: '0', height: '80px', width: '80px', paddingTop: '10px', fontSize: '1.2em', textAlign: 'center' }}>
      <div data-testid='bac-right-modal-container'>
        <ModalLink
          linkIcon='cog'
          linkClasses='text-muted'
          linkStyles={{ fontSize: '1.5em' }}
        >
          {/* the component below is inside a modal and so will not render without a trigger... */}
          <DispatchDetailBacRightModal
            expectedWeight={expected_weight}
            adjustment={adjustment}
            undoAdjustWeight={undoAdjustWeight}
            otherBacs={otherBacs}
            adjustDispatchBac={adjustDispatchBac}
            adjustDispatchWeight={adjustDispatchWeight}
          />
        </ModalLink>
      </div>

      {otherBacs && (
        <div data-testid='other-bacs-container' style={{ color: '#999', marginTop: '5px', textAlign: 'center' }} className='float-right mr-2'>
          {otherBacs.map((otherbac, index) => {
            if (index + 1 === otherBacs.length) {
              return <>{otherbac}</>
            }
            return <>{otherbac}&nbsp;-&nbsp;</>
          }
          )}
        </div>
      )}

      {showComments && (
        <div data-testid='comments-container' style={{ marginTop: '5px' }}>
          <CommentsListModal
            comments={comments}
            isMobile={isMobile}
            allowPhotos={true}
            allowVoiceComment={true}
            allowValidation={true}
            addComment={addComment}
            uploadCommentImage={uploadCommentImage}
            getPicture={getPicture}
            allowAddComment={allowAddComment}
            triggerName='icon'
          />
        </div>
      )}

    </div>
  )
}