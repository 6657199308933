import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'productinfos',
  initialState: {
    list: []
  },
  reducers: {
    fetchProductInfosRequest(state){
      state.loading = true;
      state.error = undefined;
    },
    fetchProductInfosSuccess(state, action){
      state.list = action.payload;
      state.loading = false;
    },
    fetchProductInfosFailed(state, action){
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export { reducer, actions };