import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import { Icon } from '@kelbongoo/shared-client-react-v2/dist/components/Icon';

export const HeaderContent = ({
  HeaderItemsLeft,
  HeaderItemsRight,
  isLoggingOut = false,
  onLogoutUser
}) => <>
    {HeaderItemsLeft && (
      <HeaderItemsLeft />
    )}

    <div style={{ marginLeft: 'auto' }}>

      {HeaderItemsRight && (
        <HeaderItemsRight />
      )}

      <Nav.Item style={{ display: 'inline-block' }}>
        <Button variant='link'
          disabled={!!isLoggingOut}
          onClick={onLogoutUser}
          data-testid='logout-modal-trigger'>
          <Icon name='right-from-bracket' title='logout' />
        </Button>
      </Nav.Item>
    </div>
  </>


HeaderContent.propTypes = {
  onLogoutUser: PropTypes.func.isRequired,
  HeaderItemsLeft: PropTypes.elementType,
  HeaderItemsRight: PropTypes.elementType,
  isLoggingOut: PropTypes.bool
}