import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'groupeddispatchlocales',
  initialState: {
    groupeddispatchlocales: []
  },
  reducers: {
    fetchgroupeddispatchlocalesRequest(state){
      state.loading = true;
      state.error = undefined;
    },
    fetchgroupeddispatchlocalesSuccess(state, action){
      state.groupeddispatchlocales = action.payload;
      state.loading = false;
    },
    fetchgroupeddispatchlocalesFailed(state, action){
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export { reducer, actions };