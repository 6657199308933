import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const formatItems = ({ gopps, items, addComment, toggleCompleted, updateQuantity, locale }) => {
  return items
    .map(item => {
      let gopp = gopps.find(
        g => g.producerproduct_id === item.producerproduct_id
      )
      return {
        ref_id: item._id,
        ...item,
        ...(gopp || {}),
        toggleCompleted: toggleCompleted(item.producerproduct_id),
        updateItemQuantity: updateQuantity(item.producerproduct_id),
        addComment,
        allowAddComment: true,
      }
    })
};


export const refreshItems = ({
  globalorder_id,
  page,
  locale,
  currentQuery = {},
  setItems,
  setCount,
  setLoading,
  gopps = [],
  createAlert,
  docsPerPage,
  addComment,
  toggleCompleted,
  updateQuantity
}) => {

  const params = Object.assign({
    globalorder_id,
    locale,
    dispatch_done: { $exists: false }, // non-dispatch..
    filterUnsold: true,
    getGroupedDispatchProducts: false
  }, currentQuery);

  const caller = wrapClientApiCall({
    url: 'globalorders/fetch-locale-products-list'
  });

  setLoading(true);

  return caller({
    params,
    options: { page, limit: docsPerPage || 10, sort: { product_name: 1 } },
  })
    .then(({ items, count }) => {
      setItems(formatItems({ gopps, items, addComment, toggleCompleted, updateQuantity, locale }));
      setCount(count);
    })
    .catch(error => { createAlert({ message: error.message }) })
    .finally(() => setLoading(false));

}
