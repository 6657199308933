import { wrapClientApiCall } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall';

export const toggleItemCompleted = ({
  retailrestock,
  setRetailRestock,
  createAlert
}) => (producerproduct_id, completed, quantity) => {

  const caller = wrapClientApiCall({
    url: 'boutiquerestocks/update-item',
  })

  return caller({
    params: {
      boutiquerestock_id: retailrestock._id,
      producerproduct_id,
      quantity,
      completed
    }
  })
    .then(({ updatedDoc }) => {

      const ind = retailrestock.items.findIndex(
        i => i.producerproduct_id === producerproduct_id
      );
      let updatedInd = updatedDoc.items.findIndex(
        i => i.producerproduct_id === producerproduct_id
      );
      retailrestock.items[ind].quantity_received = updatedDoc.items[updatedInd].quantity_received;
      retailrestock.items[ind].reception_completed = updatedDoc.items[updatedInd].reception_completed;
      
      setRetailRestock(retailrestock);
    })
    .catch(err => createAlert({ message: err.message }));
};