export const buildFilters = ({ producers = [] }) => {

  const searchFilter = {
    keys: ['product_name'],
    placeholder: 'Chercher un produit',
  }

  const dropdownSearchFilters = [
    {
      keys: ['producer_id'],
      filterName: 'producer_id',
      placeholder: 'Tous.tes les producteur.ices',
      FormattedSuggestion: (p) => <>{p.name}</>,
      getSuggestions: async (search) => producers
        .map((p) => ({
          name: p.producer_name,
          value: p.producer_id,
        }))
        .filter(p => { return p.name.toLowerCase().normalize().includes(search.toLowerCase().normalize()) })
        .slice(0)
        .sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        }),
      size: 'lg'
    }
  ];

  // const selectFilters = [{
  //   keys: ['producer_id'],
  //   filterName: 'producer_id',
  //   allOption: 'Tous.tes les producteur.ices',
  //   valueMapping(value) {
  //     if (value === 'all') return { producer_id: undefined };
  //     return { producer_id: value }
  //   },
  //   options: producers
  //     .map((p) => ({
  //       name: p.producer_name,
  //       value: p.producer_id,
  //     }))
  //     .slice(0)
  //     .sort((a, b) => {
  //       if (a.name < b.name) return -1;
  //       if (a.name > b.name) return 1;
  //       return 0;
  //     })
  // }]

  const quickFilter = {
    keys: ['completed'],
    defaultValue: 'not-done',
    items: [
      { value: 'all', name: 'Tous' },
      { value: 'done', name: 'Produits finis' },
      { value: 'not-done', name: 'Produits pas finis' },
    ],
    valueMapping(value) {
      if (value === 'all') return { completed: undefined };
      if (value === 'done') return { completed: true };
      if (value === 'not-done') return { completed: false };
    },
  }

  return {
    searchFilter,
    dropdownSearchFilters,
    quickFilter
  }
}