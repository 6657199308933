import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Nav from 'react-bootstrap/Nav';
import { Icon } from '@kelbongoo/shared-client-react-v2/dist/components/Icon';
import packageJson from '../../../../package.json'

export const getDropdownButtonVariant = path => {
  const role = getRoleFromPath(path);

  if (role === 'dispatch-sec') return 'outline-success';
  if (role === 'dispatch-frais') return 'outline-primary';
  if (role === 'reception-nd') return 'outline-info';
  if (role === 'reception-retail') return 'outline-light';
  if (role === 'redirections') return 'outline-warning';
  if (role === 'tasks') return 'outline-dark';

  return 'outline-primary'
}

export const getRoleFromPath = path => {
  let role = path.split('/')[1];

  if (role === 'dispatch') {
    role = `dispatch-${path.split('/')[2]}`;
  }

  return role;
};

export const getRoleName = (path) => {
  const role = getRoleFromPath(path);

  if (!role) {
    return 'Accueil';
  }

  const output = {
    'dispatch-frais': 'Dispatch frais',
    'dispatch-sec': 'Dispatch sec',
    'reception-nd': 'Reception ND',
    'reception-retail': 'Reception retail',
    'tasks': 'Tâches',
    'redirections': 'Redirections'
  }[role];

  return output || 'Accueil';
}

const DropdownItem = ({ path, className, icon, title }) =>
  <Dropdown.Item as={Link} to={path} style={{ fontSize: '1.2em', padding: '15px 17px' }} className={className}>
    <Icon name={icon} textRight />
    &nbsp; {title}
  </Dropdown.Item>

export const NavigationMenu = () => {
  const { loggedIn } = useSelector(state => state.currentUser);
  const { currentGlobalOrder } = useSelector(state => state.globalorderlocales)
  const location = useLocation();

  if (!loggedIn || !currentGlobalOrder) {
    return null;
  }

  const currentRole = getRoleFromPath(location.pathname);
  const roleName = getRoleName(location.pathname);

  return (
    <Nav.Item style={{ display: 'inline-block' }}>
      <DropdownButton
        menuAlign='right'
        variant={getDropdownButtonVariant(location.pathname)}
        title={roleName}
        style={{ zIndex: 100, marginRight: '10px' }}
      >
        <DropdownItem path="/" className={currentRole === 'home' ? 'active' : ''} icon='home' title='Accueil' />
        <DropdownItem path="/dispatch/frais" className={currentRole === 'dispatch-frais' ? 'active' : ''} icon='snowflake' title='Dispatch frais' />
        <DropdownItem path="/dispatch/sec" className={currentRole === 'dispatch-sec' ? 'active' : ''} icon='carrot' title='Dispatch sec' />
        <DropdownItem path="/reception-nd" className={currentRole === 'reception-nd' ? 'active' : ''} icon='sign-in-alt' title='Réception ND' />
        <Version />
      </DropdownButton>
    </Nav.Item>
  )
}

const Version = () =>
  <div
    style={{
      display: 'block',
      marginLeft: '16px',
      color: 'gray'
    }}
  >
    v{packageJson.version}
  </div>;

