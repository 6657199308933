import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { BarcodeButton } from '@kelbongoo/shared-client-react-v2/dist/components/BarcodeButton';
import { Datatable } from '@kelbongoo/shared-client-react-v2/dist/components/Datatable';
import { createAlert as createAlertBase } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { EnsureGlobalOrderWrapper } from '@kelbongoo/shared-client-react-v2/dist/components/SharedPrepaDistribBase';
import selectors from '../../../selectors';
import { DispatchHomeRow } from './DispatchHomeRow';
import { refreshItems } from './actions';
import { tableColumns, buildFilters, handleBarcodeScan as handleBarcodeScanBase } from './helpers';
import { toggleCompleted as toggleCompletedBase } from './actions/toggleCompleted';
import { addComment as addCommentBase } from './actions/addComment';
import { handlePersistence } from './helpers/handlePersistence';
import { formatPersistedQuery } from './helpers/formatPersistedQuery';


/**
 * 
 * @returns 
 */
export const DispatchHomeBase = () => {

  const { couloir } = useParams();
  const isMobile = !!window.cordova;

  const tableName = `dispatch-home-${couloir}`;

  const {
    persistedState,
  } = handlePersistence(tableName);

  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [docsPerPage, setDocsPerPage] = useState(10);
  const [currentQuery, setCurrentQuery] = useState(formatPersistedQuery(persistedState) || { completed: false });
  const [barcodePath, setBarcodePath] = useState();
  const { currentLocale: locale } = useSelector(state => state.locales);
  const { currentGlobalOrder: globalorder_id } = useSelector(state => state.globalorderlocales);
  const { groupeddispatchlocales } = useSelector(state => state.groupeddispatchlocales);
  const { init } = useSelector(state => state.session);
  const { gopps } = useSelector(state => state.gopps);
  const producers = selectors.getAllProducers(gopps);

  const handleBarcodeScan = handleBarcodeScanBase({ gopps, setBarcodePath });
  const createAlert = arg => dispatch(createAlertBase(arg))

  const refreshData = (setLoadingExternal) => () => {

    // groupeddispatchlocales should not be empty 
    if (barcodePath || init || !globalorder_id || !currentQuery || groupeddispatchlocales.length <= 0 || !locale) {
      return;
    }

    refreshItems({
      couloir,
      createAlert,
      currentQuery,
      docsPerPage,
      globalorder_id,
      gopps,
      locale,
      page,
      setCount,
      setItems,
      setLoading: setLoadingExternal,
      addComment,
      toggleCompleted,
      getGroupedDispatchProducts: groupeddispatchlocales.includes(locale), // WARN: règle métier
    })
  }

  const commonParams = {
    globalorder_id,
    locale,
    createAlert,
    items,
    setItems,
    refreshData: refreshData(function foo() { // this is intentional
    })
  };

  const addComment = addCommentBase(commonParams)
  const toggleCompleted = toggleCompletedBase(commonParams)

  const updateQuery = update => {
    setCurrentQuery({
      ...currentQuery,
      ...(update || { completed: false })
    })
    setPage(1)
  }

  useEffect(() => {
    setPage(1);
  }, [couloir]);

  useEffect(refreshData(setLoading), [couloir, currentQuery, page, barcodePath, globalorder_id, gopps, locale, docsPerPage, init, groupeddispatchlocales]);

  if (barcodePath) {
    return <Redirect to={`/dispatch/${couloir}/${barcodePath}`} />
  }

  const {
    searchFilter,
    quickFilter,
    dropdownSearchFilters
  } = buildFilters({ producers });

  return (
    <Container fluid className={tableName}>

      {isMobile && (
        <Container className='m-2' fluid>
          <BarcodeButton
            onSuccess={handleBarcodeScan}
            onError={console.error}
          />
        </Container>
      )}

      <Datatable
        columns={tableColumns}
        count={count}
        currentPage={page}
        DatatableRow={DispatchHomeRow}
        docsPerPage={docsPerPage}
        items={items}
        loading={loading || init}
        quickFilter={quickFilter}
        searchFilter={searchFilter}
        dropdownSearchFilters={dropdownSearchFilters}
        showPagination={true}
        tableName={tableName}
        updateDocsPerPage={setDocsPerPage}
        updatePage={setPage}
        updateQuery={updateQuery}
        useSelector={useSelector}
        dispatch={dispatch}
      />

    </Container>
  )
};

export const DispatchHome = props => (
  <EnsureGlobalOrderWrapper>
    <DispatchHomeBase {...props} />
  </EnsureGlobalOrderWrapper>
);