import { wrapClientApiCall } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall';

export const updateStatus = ({
  globalorder_id,
  redirection,
  setRedirection,
  createAlert,
}) => status => () => {

  const caller = wrapClientApiCall({
    url: `globalorderredirections/mark-${status}`,
  })

  return caller({
    params: {
      globalorder_id,
      redirection_id: redirection._id
    }
  })
    .then(({ updatedDoc }) => {

      redirection.status = updatedDoc.status;
      redirection[`${status}At`] = updatedDoc[`${status}At`];
      setRedirection(redirection);
    })
    .catch(err => createAlert(err));
};