import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";


export const loadData = ({
  globalorder_id,
  locale,
  producerproduct_id,
  setCurrentDispatch,
  setOrderComments,
  setLoading,
  setGopp,
  createAlert,
  couloir,
  gopps = [],
  getGroupedDispatchProducts,
  excludedLocales
}) => {

  const fetchLocaleListCaller = wrapClientApiCall({
    url: 'globalorders/fetch-locale-products-list',
  });
  const getDispatchCaller = wrapClientApiCall({
    url: 'globalorders/get-dispatch',
  });

  return Promise.all([
    fetchLocaleListCaller({
      params: {
        globalorder_id,
        locale,
        producerproduct_id,
        user_id: '2W8X2SaMhytesy4ne',
        couloir: couloir === 'sec' ? 1 : 0,
        dispatch_done: couloir === 'sec' || couloir === 'frais' ? { $exists: true} : { $exists: false},
        getGroupedDispatchProducts,
        excludedLocales
      }
    }),
    getDispatchCaller({
      params: {
        globalorder_id,
        locale,
        producerproduct_id,
        user_id: '2W8X2SaMhytesy4ne',
        couloir,
        getGroupedDispatchProducts,
        excludedLocales
      }
    }),
  ])
    .then(([{ items }, { dispatch, orderComments }]) => {
      let formattedItems = items.map(item => {
        if(item.locale_progress) {
          item.locale_progress = item.locale_progress;
        }
        
        let completed = item.locale_progress && item.locale_progress.reduce((p, c) => p && c.completed, true);

        item.completed = completed;
        

        let gopp = gopps.find(
          g => g.producerproduct_id === item.producerproduct_id
        )

        return {
          ref_id: item._id,
          ...item,
          ...(gopp || {}),
          couloirName: couloir,
        }
      })

      setGopp(formattedItems[0]);
      setCurrentDispatch(dispatch);
      setOrderComments(orderComments);
    })
    .catch(err => {
      console.error('err', err)
      createAlert({ message: err.message })
    })
    .finally(() => setLoading(false));
};