import React from 'react';
import PropTypes from 'prop-types';
import Badge from 'react-bootstrap/Badge';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import { Icon } from '@kelbongoo/shared-client-react-v2/dist/components/Icon';
import { DispatchDetailBacRight } from './DispatchDetailBacRight';
import { run as throttle } from '@kelbongoo/shared-methods/utils/throttle';
import { getVariantFromLocale } from '../../../../helpers/getVariantFromLocale';

export const DispatchDetailBac = ({
  running,
  number,
  items,
  other_bacs = [],
  comments = [],
  showComments,
  addComment,
  allowAddComment,
  uploadCommentImage,
  getPicture,
  updateQuantity,
  expected_weight,
  adjustment,
  undoAdjustDispatchWeight,
  adjustDispatchBac,
  adjustDispatchWeight,
  dispatchOne,
  toggleCompleted,
  isMobile,
  locale,
  locales,
  is_grouped_dispatch,
  quantity_received,
  quantity_ordered,
  quantity_expected,
  refreshData
}) => {

  const handleClick = throttle(() => {
    dispatchOne(items[0], locale)
  }, 100);

  let initialQuantity = parseInt(quantity_expected) && parseInt(quantity_expected) !== parseInt(items.length) ? `(${quantity_expected})` : ``;

  return (
    <ListGroupItem className={`p-5 text-center ${running ? 'running' : ''}`}>

      <div className='dispatch-queue-list-item pointer' onClick={handleClick} style={{display: "flex", justifyContent: 'center',
        alignContent: 'center',
      }}>
        <div className='row' style={{width: "60%"}}>
          <div className='col-1'>
            {adjustment && (
              <Icon
                name='exclamation-circle'
                data-testid='adjustment-warning'
                textRight
                style={{ verticalAlign: 'top', color: 'orange', width: "30%", height: "1%" }}
              />
            )}
          </div>
          <div className='col-4' style= {{
              justifyContent: "right",
              display: "flex",
              alignItems: "center",
          }}>
            {is_grouped_dispatch && <span className='text-2xl align-middle m-2'><Badge data-testid='bac-locale' className='bold' variant={getVariantFromLocale(locale, locales)}>{locale}</Badge></span>}
          </div>
          <div className='col-3 d-flex' style={{
                paddingLeft: 0,
                marginLeft: 0
          }}>
            <span data-testid='bac-number' className='bold text-6xl align-middle'>{number}</span>
            <span>
                {(items.length > 1) && (
                  <span className='text-4xl align-middle m-2'><Badge
                    data-testid='bac-dispatch-quantity'
                    variant='secondary'
                  >
                    {items.length} {initialQuantity}
                  </Badge>
                  </span>
                )}
            </span>

          </div>

        </div>
        
        

        


      </div>

      <DispatchDetailBacRight
        expected_weight={expected_weight}
        adjustment={adjustment}
        undoAdjustWeight={undoAdjustDispatchWeight}
        otherBacs={other_bacs}
        adjustDispatchBac={adjustDispatchBac}
        adjustDispatchWeight={adjustDispatchWeight}
        showComments={showComments}
        comments={comments}
        addComment={addComment}
        uploadCommentImage={uploadCommentImage}
        getPicture={getPicture}
        allowAddComment={allowAddComment}
        isMobile={isMobile}
        quantity_received={quantity_received}
        quantity_ordered={quantity_ordered}
        quantity_expected={quantity_expected}
        updateQuantity={updateQuantity}
        refreshData={refreshData}
      />
    </ListGroupItem>
  );
};

DispatchDetailBac.propTypes = {
  running: PropTypes.bool,
  adjustment: PropTypes.bool,
  number: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  otherBacs: PropTypes.arrayOf(PropTypes.number),
  comments: PropTypes.arrayOf(PropTypes.shape({
    comment: PropTypes.string.isRequired
  })),
  showComments: PropTypes.bool,
  addComment: PropTypes.func,
  allowAddComment: PropTypes.bool,
  uploadCommentImage: PropTypes.func,
  getPicture: PropTypes.func,
  expected_weight: PropTypes.number,
  undoAdjustDispatchWeight: PropTypes.func,
  adjustDispatchBac: PropTypes.func,
  adjustDispatchWeight: PropTypes.func,
  dispatchOne: PropTypes.func,
  toggleCompleted: PropTypes.func,
  isMobile: PropTypes.bool,
  locale: PropTypes.string,
  locales: PropTypes.arrayOf(PropTypes.string),
  is_grouped_dispatch: PropTypes.bool,
};