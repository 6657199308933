
/**
 * handles the details for dispatch/undispatch of a single dispatch item, based on its dispatch_id
 * NOTE this is NOT used to calculate undispatching methods, it's only used as a rollback for a server
 * error on dispatchOne...
 * @param {object[]} currentDispatch - objects representing bacs with dispatch items [{ number, items, done }]
 * @param {object} gopp - the gopp object
 * @param {string} dispatch_id - the _id of the dispatch item to be manipulated
 * @param {boolean} undo - are we undoing or dispatching ?
 * @param {number[]} bacsDone - array of bac numbers dispatched - no doubles 
 * for multiple dispatch, in order of last dispatched. to make undoing recent dispatch easy
 * @returns {object} - { currentDispatch, gopp, bacsDone }, updated values
 */

const clone = o => JSON.parse(JSON.stringify(o));

export const transferDispatch = ({
  currentDispatch,
  gopp,
  dispatch_id,
  undo,
  bacsDone,
}) => {

  const source = undo ? 'done' : 'items';
  const destination = undo ? 'items' : 'done';
  const currentDispatchIndex = currentDispatch.findIndex(
    bac => bac.items.includes(dispatch_id) || bac.done.includes(dispatch_id)
  );
  const bacNumber = currentDispatch[currentDispatchIndex].number;
  let bacsDoneIndex = bacsDone.findIndex(
    bd => bd === bacNumber
  )

  const goppCloned = clone(gopp);
  const currentDispatchCloned = clone(currentDispatch);
  const bacsDoneCloned = clone(bacsDone);

  if (
    undo
    && currentDispatchCloned.every(bac => bac.items.length === 0)
  ) {
    /*
      if all the bac.items arrays are empty, the gopp should have been marked completed
      so if we undo any dispatch item, we must unmark completed as well
    */
    goppCloned.completed = false;
  }

  if (!undo) {
    /*
      push this bac number as the latest one to be dispatched
      if the bac number is already in the bacs done array, remove it
    */
    if (bacsDoneIndex > -1) {
      bacsDoneCloned.splice(bacsDoneIndex, 1);
    }

    bacsDoneCloned.push(bacNumber);
  }

  if (
    undo
    && currentDispatchCloned[currentDispatchIndex].done.length === 1
  ) {
    /*
      if we are undoing the only so-far dispatched item(s) for the bac,
      remove the bac number from the bacsDone array
    */
    if (bacsDoneIndex > -1) {
      bacsDoneCloned.splice(bacsDoneIndex, 1);
    }
  }

  // remove the dispatch_id from the source array
  currentDispatchCloned[currentDispatchIndex][source].splice(
    currentDispatchCloned[currentDispatchIndex][source].indexOf(dispatch_id),
    1
  );
  // append the dispatch_id to the destination array
  currentDispatchCloned[currentDispatchIndex][destination].push(dispatch_id);

  if (
    !undo
    && currentDispatchCloned.every(bac => bac.items.length === 0)
  ) {
    // if all the items arrays are now empty, the gopp's dispatch is done,
    // so mark it completed !
    goppCloned.completed = true;
  }

  return {
    currentDispatch: currentDispatchCloned,
    gopp: goppCloned,
    bacsDone: bacsDoneCloned
  };
};