import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";


export const updateQuantity = ({
  locale,
  globalorder_id,
  createAlert,
  refreshData
}) => producerproduct_id => quantity => {

  const caller = wrapClientApiCall({
    url: 'globalorders/update-nondispatch-quantity-received',
  })

  return caller({
    params: {
      globalorder_id,
      locale,
      producerproduct_id,
      quantity,
    }
  })
    .then(refreshData())
    .catch(err => createAlert(err));
};