import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { ModalLink } from '@kelbongoo/shared-client-react-v2/dist/components/ModalLink';
import { Icon } from '@kelbongoo/shared-client-react-v2/dist/components/Icon';
import { CommentsListModal } from '@kelbongoo/shared-client-react-v2/dist/components/CommentsListModal';

import { DispatchDetailHeaderAllDispatchModal } from '../DispatchDetailHeaderAllDispatchModal';
import styles from './styles.module.css';
import { blurAll } from '../../../../helpers/blurAll';

const ProgressBar = ({ progress }) => (<div>
  <div className='progress-line' style={{ width: `${progress}%` }}></div>
</div>);

export const DispatchDetailHeader = ({
  locale_progress,
  product_name,
  producer_name,
  unit_display,
  comments,
  couloirName,
  progress,
  recentlyDispatchedBacs = [],
  addComment,
  toggleCompleted,
  groupeddispatchlocales,
  locale,
  completed
}) => {

  let handleAction = (action) => {
    blurAll();
    return action;
  }

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Link to={`/dispatch/${couloirName}`} className={styles.firstButton}>
              <Icon name='arrow-left' style={{ color: '#333' }} />
            </Link>

            <div className='text-md'>
              <ModalLink
                modalHeaderTitle='Bacs et quantités'
                linkText={product_name}
                linkStyles={{ padding: 0, textDecoration: 'none' }}
                onHide={blurAll}
                onExit={blurAll}
              >
                <DispatchDetailHeaderAllDispatchModal
                  locale_progress={locale_progress}
                  locale={locale} groupeddispatchlocales={groupeddispatchlocales} 
                />
              </ModalLink>
              <div>{producer_name}</div>
              <div className='text-muted italic'>{unit_display}</div>
            </div>
          </div>

          <div className={styles.actions}>
            <Button variant='link' style={{ opacity: (completed ? '1' : '0.3') }} className={completed ? 'text-success' : 'text-muted'} onClick={toggleCompleted(!completed)}>
              <Icon name={completed ? 'check-circle' : 'check'} className='text-3xl' />
            </Button>
            <CommentsListModal
              comments={comments}
              triggerName='icon'
              triggerIconBadgeAlignRight={true}
              allowPhotos={true}
              allowVoiceComment={true}
              allowValidation={true}
              allowAddComment={true}
              addComment={handleAction(addComment)}
              uploadCommentImage={() => { }}
              getPicture={() => { }}
            />
          </div>
        </div>
        <ProgressBar progress={progress} />
      </div>
      {
        recentlyDispatchedBacs.length > 0 ? (
          <div className='text-center bold text-md' style={{ minHeight: '40px', marginTop: '10px' }}>
            {recentlyDispatchedBacs ? (
              <div>
                {recentlyDispatchedBacs.map((b, i) => <span className={`text-2xl text-muted ${i === recentlyDispatchedBacs.length - 1 ? '' : 'mr-5'}`}>{b}</span>)}
              </div>
            ) : (
              <Icon name='carrot' className='text-muted' />
            )}
          </div>
        ): null
      }
      
    </>
  );
};
