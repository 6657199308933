import { wrapClientApiCall } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall';
import cloneDeep from 'lodash.clonedeep';

export const toggleMarkDone = ({
  taskinstances,
  setTaskInstances,
  createAlert
}) => taskinstance_id => () => {

  const caller = wrapClientApiCall({
    url: 'taskinstances/toggle-mark-done',
  });

  return caller({
    params: { taskinstance_id }
  })
    .then(({ updatedDoc }) => {

      const updatedTaskInstances = cloneDeep(taskinstances);

      const index = updatedTaskInstances.findIndex(
        t => t._id === taskinstance_id
      )
      updatedTaskInstances[index].doneAt = updatedDoc.doneAt;
      updatedTaskInstances[index].doneBy = updatedDoc.doneBy;
      
      setTaskInstances(updatedTaskInstances);
    })
    .catch(err => createAlert({ message: err.message }));
};