import { createTaskInstanceGroupEvent } from '@kelbongoo/shared-methods/taskinstances/collection';

export const formatEvents = ({ tasks, globalorderlocale }) => {

  const output = [
    {
      event_type: 'preparation',
      title: 'Dispatch frais',
      isDone: globalorderlocale 
				&& typeof globalorderlocale.dispatch_frais_pct !== 'undefined'
				&& globalorderlocale.dispatch_frais_pct >= 100,
      hasProgress: true,
      progressPct: globalorderlocale && globalorderlocale.dispatch_frais_pct,
      hideDoneInfo: true,
      path: '/dispatch/frais'
    },
    {
      event_type: 'preparation',
      title: 'Dispatch sec',
      isDone: globalorderlocale 
				&& typeof globalorderlocale.dispatch_sec_pct !== 'undefined'
				&& globalorderlocale.dispatch_sec_pct >= 100,
      hasProgress: true,
      progressPct: globalorderlocale && globalorderlocale.dispatch_sec_pct,
      hideDoneInfo: true,
      path: '/dispatch/sec'
    },
    {
      event_type: 'reception',
      title: 'Reception non-dispatch',
      isDone: globalorderlocale 
				&& typeof globalorderlocale.nondispatch_check_pct !== 'undefined'
				&& globalorderlocale.nondispatch_check_pct >= 100,
      hasProgress: true,
      progressPct: globalorderlocale && globalorderlocale.nondispatch_check_pct,
      hideDoneInfo: true,
      path: '/reception-nd'
    }
  ];
  if(tasks && tasks.length > 0){
    output.push(
			createTaskInstanceGroupEvent(tasks, { url: '/tasks' })
		);
  }
  return output;
};