import { toggleCompleted, refreshData } from "../actions";
import { orderBy } from 'lodash';
import { isCompleted } from "../../../../helpers/isCompleted";

export const filterByDirectionAndStartNumber =
  ({ reverseDispatchPath, currentStartNumber }) =>
    (d) => {
      const valid =
        !currentStartNumber ||
        currentStartNumber === 1 ||
        (reverseDispatchPath
          ? d.number <= currentStartNumber
          : d.number >= currentStartNumber);

      return !d.dispatchedAt && valid;
    };

export const formatBac =
  ({
    orderComments,
    dispatchOne,
    addBacComment,
    undoAdjustDispatchWeight,
    adjustDispatchWeight,
    adjustDispatchBac,
    gopp,
    globalorder_id,
    producerproduct_id,
    createAlert,
    locales,
    setCurrentDispatch,
    couloir,
    getGroupedDispatchProducts,
    updateQuantity,
    loadData,
  }) =>
    (d) => {
      let order = orderComments.find((o) => o.bacs.includes(d.number));

      Object.assign(d, {
        dispatchOne,
        toggleCompleted: toggleCompleted({
          globalorder_id,
          locale: d.locale,
          producerproduct_id,
          createAlert,
          excludedLocales: locales.filter((l) => l !== d.locale),
          setCurrentDispatch,
          couloir,
          getGroupedDispatchProducts,
          loadData,
        })(),
        refreshData: refreshData({
          globalorder_id,
          locale: d.locale,
          producerproduct_id,
          setCurrentDispatch,
          createAlert,
          couloir,
          getGroupedDispatchProducts,
          excludedLocales: [],
        }),
        adjustDispatchWeight: adjustDispatchWeight({
          order_id: d.order_id,
          dispatch_id: d.items[0],
        }),
        undoAdjustDispatchWeight: undoAdjustDispatchWeight({
          order_id: d.order_id,
          dispatch_id: d.items[0],
        }),
        adjustDispatchBac: adjustDispatchBac({
          order_id: d.order_id,
          dispatch_id: d.items[0],
        }),
        addComment: addBacComment(d.number),
        updateQuantity: updateQuantity(d.locale),
        comments: order ? order.comments : [],
        expected_weight: gopp.unit_weight
      });

      return d;
    };

export const formatBacs = ({
  currentDispatch,
  gopp,
  reverseDispatchPath,
  currentStartNumber,
  orderComments = [],
  dispatchOne,
  addBacComment,
  undoAdjustDispatchWeight,
  adjustDispatchWeight,
  adjustDispatchBac,
  locales,
  globalorder_id,
  producerproduct_id,
  createAlert,
  setCurrentDispatch,
  couloir,
  getGroupedDispatchProducts,
  updateQuantity,
  loadData,
}) => {
  if (!gopp) {
    return [];
  }

  if (isCompleted(false, gopp) === true) {
    return [];
  }

  const dispatchesToDo = (d) => d.items.length > 0;
  
  const unorderedDispatches = currentDispatch
    .filter(dispatchesToDo)
    .filter(filterByDirectionAndStartNumber({
      reverseDispatchPath,
      currentStartNumber: Number(currentStartNumber),
    }))
    .map(formatBac({
      orderComments,
      dispatchOne,
      addBacComment,
      undoAdjustDispatchWeight,
      adjustDispatchWeight,
      adjustDispatchBac,
      gopp,
      globalorder_id,
      producerproduct_id,
      createAlert,
      locales,
      setCurrentDispatch,
      couloir,
      getGroupedDispatchProducts,
      updateQuantity,
      loadData,
    }));

  return orderBy(
    unorderedDispatches,
    [
      d => !d.partner,
      d => d.locale,
      d => d.number
    ],
    [
      'asc',
      reverseDispatchPath ? 'desc' : 'asc',
      reverseDispatchPath ? 'desc' : 'asc',
    ]
  );
};