import { refreshItems } from "../components/pages/DispatchHome/actions";

export function isCompleted(isND, gopp) {
    console.log(gopp, "GOPP")
    if (gopp.hasOwnProperty("locale_progress")){
        const filteredLocaleProgress = gopp.locale_progress.filter(
            (progress) => {
                const hasDispatchDone = progress.hasOwnProperty("dispatch_done") || progress.dispatch_done !== undefined;
                if (isND === true) {
                    // Vérifier les éléments qui n'ont pas la propriété "dispatch_done"
                    const condition = !hasDispatchDone;
                    return condition;
                } else {
                    console.log("pas ND", progress.locale, progress.completed)
                    // Vérifier les éléments qui ont la propriété "dispatch_done"
                    const condition = hasDispatchDone;
                    return condition;
                }
            }
        )
        return filteredLocaleProgress.every((progress) => progress.completed)

    } 
    return false
  }