export const onValidation = ({ 
  setRunning, 
  undoAdjustWeight, 
  adjustDispatchBac, 
  adjustDispatchWeight,
  closeModal
}) => ({
  isUndoWeightAdjustment, 
  isAdjustBac, 
  isWeightAdjustment,
  ...data
}) => {

  setRunning(true);
  let promise;

  if(isUndoWeightAdjustment){
    promise = undoAdjustWeight()
  }
  else {
    if(isAdjustBac){

      if(typeof data.bac === 'undefined'){
        return;
      }
      promise = adjustDispatchBac(data)
    }
    if(isWeightAdjustment){

      if(typeof data.weight === 'undefined'){
        return;
      }

      promise = adjustDispatchWeight(data)
    }
  }

  promise.finally(() => {
    setRunning(false)
    closeModal();
  })
}