import React from 'react';
import { DefaultForm } from '@kelbongoo/shared-client-react-v2/dist/components/DefaultForm';


export const DispatchDetailManageSurplusModal = ({
  updateCurrentSurplus,
  currentSurplus
}) => {

  return (
    <DefaultForm
      showValidation={true}
      formClasses='margin-bottom-xl'
      formId='update-surplus'
      groups={[]}
      item={{ currentSurplus }}
      elements={[
        {
          key: 'surplus',
          title: 'Quantité surplus',
          type: 'number',
          dataType: 'number',
          inputClasses: 'input-lg text-center',
          column: 1,
          component: 'input',
          required: true
        }
      ]}
      onValidation={updateCurrentSurplus}
    />
  )
}