import React from 'react';
import { NoGlobalOrders as NoGlobalOrdersBase, EnsureGlobalOrderWrapper } from '@kelbongoo/shared-client-react-v2/dist/components/SharedPrepaDistribBase';
import { ErrorBoundary } from '../../ui/ErrorBoundary';


export const NoGlobalOrders = props => (
	<ErrorBoundary>
    <EnsureGlobalOrderWrapper currentPath='no-globalorders'>
		  <NoGlobalOrdersBase {...props} />
    </EnsureGlobalOrderWrapper>
	</ErrorBoundary>
)