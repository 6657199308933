import React from 'react';
import { ModalButton } from '@kelbongoo/shared-client-react-v2/dist/components/ModalButton';
import { Icon } from '@kelbongoo/shared-client-react-v2/dist/components/Icon';
import { IconText } from '@kelbongoo/shared-client-react-v2/dist/components/IconText';
import { DispatchDetailManageSurplusModal } from './DispatchDetailManageSurplusModal';


export const DispatchDetailDone = ({
  completed,
  showSurplus,
  updateCurrentSurplus,
  currentSurplus
}) => {

  if(!completed){

    return (
      <>
        <div className='text-center mt-4'>
          <Icon name='minus'  style={{color: '#bababa'}} />
        </div>
        <div data-testid='no-dispatch' className='text-center text-muted italic' style={{color:'#bababa'}}>
          Rien &agrave; dispatcher
        </div>
      </>
    )
  }

  return (
    <>
      <div data-testid='dispatch-done' className='fs-10 text-center mb-8 mt-10'>
        <IconText icon='check' text='Fini !' className='text-success' />
      </div>
      {showSurplus ? (
        <div className='text-center' data-testid='surplus-container'>
          <ModalButton>
            <DispatchDetailManageSurplusModal
              updateCurrentSurplus={updateCurrentSurplus}
              currentSurplus={currentSurplus}
            />
          </ModalButton>
        </div>
      ) : ''}
    </>
  )
}