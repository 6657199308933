import React from 'react';
import { useDispatch } from 'react-redux';
import { HeaderBase } from './HeaderBase';
import { ErrorBoundary as ErrorBoundaryBase } from '@kelbongoo/shared-client-react-v2/dist/components/ErrorBoundary';
import { NavigationMenu } from './NavigationMenu';
import Nav from 'react-bootstrap/Nav';
import { SelectedGlobalDate } from './SelectedGlobalDate';
import { LocaleBadgeList } from './LocaleBadgeList';

const ErrorBoundary = ErrorBoundaryBase({});

const HeaderItemsLeft = () =>
	<Nav.Item style={{ display: 'inline-block' }}>
		<LocaleBadgeList />
		<SelectedGlobalDate />
	</Nav.Item>;

export const Header = () =>
	<ErrorBoundary>
		<HeaderBase
			HeaderItemsRight={NavigationMenu}
			HeaderItemsLeft={
				HeaderItemsLeft
			}
			dispatch={useDispatch()}
		/>
	</ErrorBoundary>
