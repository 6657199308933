import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container';
import { LoadingSpinner } from '@kelbongoo/shared-client-react-v2/dist/components/LoadingSpinner';
import { Icon } from '@kelbongoo/shared-client-react-v2/dist/components/Icon';
import { RestocksDetailView } from '@kelbongoo/shared-client-react-v2/dist/components/RestocksDetailView';
import { EnsureGlobalOrderWrapper } from '@kelbongoo/shared-client-react-v2/dist/components/SharedPrepaDistribBase';
import { createAlert } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { ErrorBoundary } from '../../ui/ErrorBoundary';
import { 
  refreshData,
  addComment as addCommentBase,
  markArrived as markArrivedBase,
  toggleItemCompleted as toggleItemCompletedBase,
  updateItemQuantity as updateItemQuantityBase
} from './actions';



export const ReceptionRetailBase = () => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [retailrestock, setRetailRestock] = useState();
  const { init } = useSelector(state => state.session);
  const { currentLocale: locale } = useSelector(state => state.locales);
  const { currentGlobalOrder: globalorder_id } = useSelector(state => state.globalorderlocales);
  const commonParams = {
    globalorder_id,
    retailrestock,
    setRetailRestock,
    createAlert: arg => dispatch(createAlert(arg)),
    locale
  }

  const addComment = addCommentBase(commonParams)
  const markArrived = markArrivedBase(commonParams)
  const toggleItemCompleted = toggleItemCompletedBase(commonParams)
  const updateItemQuantity = updateItemQuantityBase(commonParams)


  useEffect(() => {

    if(init){
      return;
    }

    refreshData({ 
      ...commonParams,
      setLoading
    })
  }, [locale, init]);

  if(loading){
    return <LoadingSpinner />
  }

  if(!retailrestock){

    return (
      <Container>
        <div class='margin-bottom-lg'>
          <Icon name='exclamation-circle'  className='text-warning' />
        </div>
        <h4>
          Aucun retail programm&eacute; pour cette distribution
        </h4>
      </Container>
    )
  }

  return (
    <RestocksDetailView 
      restock={retailrestock}
      formatItems={a => a}
      getProgressPct={() => 0}
      getReferenceIdsFromItems={() => 'foo'}
      getTotals={() => ({})}
      addComment={addComment}
      markArrived={markArrived}
      toggleItemCompleted={toggleItemCompleted}
      updateItemQuantity={updateItemQuantity}
    />
  )
}

export const ReceptionRetail = props => (
	<ErrorBoundary>
		<EnsureGlobalOrderWrapper>
			<ReceptionRetailBase {...props} />
		</EnsureGlobalOrderWrapper>
	</ErrorBoundary>
)