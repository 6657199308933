import { wrapClientApiCall } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall';

export const addComment = ({
  retailrestock,
  setRetailRestock,
  createAlert
}) => comment => {

  const caller = wrapClientApiCall({
    url: 'boutiquerestocks/add-comment',
  })

  return caller({
    params: {
      retailrestock_id: retailrestock._id, 
      comment
    }
  })
    .then(({ comments }) => {

      retailrestock.comments = comments;
      setRetailRestock(retailrestock);
    })
    .catch(err => createAlert({ message: err.message }));
};