import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const addGoppComment = ({ 
  gopp, 
  setGopp,
  createAlert
}) => (comment, isPhoto = false) => {

  const caller = wrapClientApiCall({
    url: 'globalorderproducerproducts/add-comment',
  })

  return caller({
    params: { 
      reference_id: gopp.ref_id, 
      context: 'preparations',
      comment, 
      metadata: { isPhoto },
      userId: '2W8X2SaMhytesy4ne'
    }
  })
    .then(({ comments }) => {
      const updatedGopp = JSON.parse(JSON.stringify(gopp));
      updatedGopp.comments = comments;
      setGopp(updatedGopp);
    })
    .catch((err) => createAlert({ message: err.message }));
};