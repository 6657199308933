export const handlePersistence = (tableName, initialParams) => {

  let persistenceKey, persistDatatableState;

  try {

    persistenceKey = `datatable_${tableName}`;

    persistDatatableState = params => {
      
      let oldParamsString = sessionStorage.getItem(persistenceKey), 
        oldParams = {}

      if(oldParamsString !== null){
        try {
          oldParams = JSON.parse(oldParamsString);
        } catch(err){}
      }

      let newParams = { ...oldParams, ...params }
      sessionStorage.setItem(persistenceKey, JSON.stringify(newParams));
    };

    if(initialParams){
      persistDatatableState(initialParams);
    }

    let persistedStateString = sessionStorage.getItem(persistenceKey), 
      persistedState = {};

    if(persistedStateString !== null){
      try {
        persistedState = JSON.parse(persistedStateString);
      } catch(err){}
    }

    return {
      persistDatatableState,
      persistedState
    }

  }
  catch (error) {
    // clear the storage
    console.error('handlePersistence', error);
    sessionStorage.setItem(persistenceKey, JSON.stringify({}));

    return {
      persistDatatableState,
      persistedState: {}
    }
  }

}