import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";
import { refreshData } from './refreshData';

export const undispatchOne = ({
  globalorder_id,
  locale: localeExternal,
  producerproduct_id,
  setCurrentDispatch,
  createAlert,
  couloir,
  getGroupedDispatchProducts,
  excludedLocales
}) => (dispatch_id, locale) => {

  const caller = wrapClientApiCall({
    url: 'globalorderlocaleproducerproducts/undispatch-one',
  });

  return caller({
    params: {
      dispatch_id,
      globalorder_id,
      locale: locale || localeExternal,
      producerproduct_id,
      userId: '2W8X2SaMhytesy4ne'
    },
  })
    .then(refreshData({
      globalorder_id,
      locale: localeExternal,
      producerproduct_id,
      setCurrentDispatch,
      createAlert,
      couloir,
      getGroupedDispatchProducts,
      excludedLocales
    }))
    .catch((err) => {
      createAlert({ message: err.message })
    });
};
