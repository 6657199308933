export const buildFilters = ({ locales = [] }) => {

  const searchFilter = {
    keys: ['product_name'],
    placeholder: 'Chercher un produit'
  }

  const quickFilter = {
    keys: ['preparedAt'],
    value: 'not-done',
    items: [
      { value: 'all', name: 'Tous' },
      { value: 'done', name: 'Finis' },
      { value: 'not-done', name: 'Pas finis' },
    ],
    valueMapping(value){
      if(value === 'all') return {};
      if(value === 'done') return { preparedAt: { $exists: true }}
      if(value === 'not-done') return { preparedAt: { $exists: false } }
    }
  }

  const selectFilters = [{
    keys: ['producer_id'],
    options: [
      { value: undefined, name: 'Tous les locaux' },
      ...locales.map(p => ({
        value: p.code,
        name: p.code
      }))
    ]
  }]

  return {
    searchFilter,
    quickFilter,
    selectFilters
  }
}