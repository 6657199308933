import { createSlice } from '@reduxjs/toolkit';
import { globalorderlocalesInitialState, globalorderlocalesReducers } from '@kelbongoo/shared-client-react-v2/dist/components/SharedPrepaDistribBase';

const { reducer, actions } = createSlice({
  name: 'globalorderlocales',
  initialState: globalorderlocalesInitialState,
  reducers: {
    ...globalorderlocalesReducers
  }
});

export { reducer, actions };