import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container'
import Alert from 'react-bootstrap/Alert'
import Badge from 'react-bootstrap/Badge'
import Dropdown from 'react-bootstrap/Dropdown'
import { Datatable } from '@kelbongoo/shared-client-react-v2/dist/components/Datatable';
import { createAlert as createAlertBase } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { EnsureGlobalOrderWrapper } from '@kelbongoo/shared-client-react-v2/dist/components/SharedPrepaDistribBase';
import { ReceptionNDRow } from './ReceptionNDRow';
import selectors from '../../../selectors';
import { tableColumns, buildFilters } from './helpers';
import {
  refreshItems,
  addComment as addCommentBase,
  toggleCompleted as toggleCompletedBase,
  updateQuantity as updateQuantityBase
} from './actions';

import { handlePersistence } from './helpers/handlePersistence';
import { formatPersistedQuery } from './helpers/formatPersistedQuery';
import { getVariantFromLocale } from '../../../helpers/getVariantFromLocale';

export const ReceptionNDBase = () => {


  const tableName = 'reception-nd';

  const {
    persistedState,
  } = handlePersistence(tableName)

  const dispatch = useDispatch();
  const [items, setItems] = useState([])
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [docsPerPage, setDocsPerPage] = useState(10);
  const [currentQuery, setCurrentQuery] = useState(formatPersistedQuery(persistedState) || { completed: false });

  const { currentLocale: locale } = useSelector(state => state.locales);
  const [selectedLocale, setSelectedLocale] = useState(locale);
  const { currentGlobalOrder: globalorder_id } = useSelector(state => state.globalorderlocales);
  const { init } = useSelector(state => state.session);
  const { gopps } = useSelector(state => state.gopps);
  const { groupeddispatchlocales } = useSelector(state => state.groupeddispatchlocales);
  const producers = selectors.getAllProducers(gopps);

  const createAlert = arg => dispatch(createAlertBase(arg))

  const refreshData = (setLoadingExternal) => () => {

    if (init || !globalorder_id || !currentQuery) {
      return;
    }

    refreshItems({
      globalorder_id,
      locale: selectedLocale,
      items,
      setItems,
      createAlert,
      currentQuery,
      docsPerPage,
      gopps,
      page,
      setCount,
      setLoading: setLoadingExternal,
      addComment,
      toggleCompleted,
      updateQuantity
    })
  }

  const commonParams = {
    globalorder_id,
    locale: selectedLocale,
    items,
    setItems,
    createAlert,
    refreshData: refreshData(function foo() { // this is intentional 
    }),
    excludedLocales: groupeddispatchlocales.filter(l => l !== selectedLocale)
  };

  const addComment = addCommentBase(commonParams)
  const toggleCompleted = toggleCompletedBase(commonParams)
  const updateQuantity = updateQuantityBase(commonParams)

  const updateQuery = update => {
    setCurrentQuery({
      ...currentQuery,
      ...(update || { completed: false })
    })
    setPage(1)
  }

  useEffect(refreshData(setLoading), [currentQuery, page, globalorder_id, gopps, locale, selectedLocale, docsPerPage, init]);

  const {
    searchFilter,
    dropdownSearchFilters,
    quickFilter
  } = buildFilters({ producers });

  return (
    <Container fluid className='reception-nd'>
      {groupeddispatchlocales.includes(locale) && <Alert style={{background: "#fff"}}>
        <Dropdown >
          Produits ND uniquement de&nbsp;
          <Dropdown.Toggle variant={getVariantFromLocale(selectedLocale)} id="dropdown-locale" size='sm'>
            {selectedLocale}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {groupeddispatchlocales.map(loc => {
              return <Dropdown.Item key={loc} onClick={() => setSelectedLocale(loc)}>
                <Badge variant={getVariantFromLocale(loc)} >{loc}</Badge>
              </Dropdown.Item>;
            })}
          </Dropdown.Menu>
        </Dropdown>

      </Alert>}

      <Datatable
        columns={tableColumns}
        count={count}
        currentPage={page}
        DatatableRow={ReceptionNDRow}
        dispatch={dispatch}
        docsPerPage={docsPerPage}
        dropdownSearchFilters={dropdownSearchFilters}
        getItemId={(item) => item._id}
        items={items}
        loading={loading || init}
        quickFilter={quickFilter}
        searchFilter={searchFilter}
        showPagination={true}
        tableName={tableName}
        updateDocsPerPage={setDocsPerPage}
        updatePage={setPage}
        updateQuery={updateQuery}
        useSelector={useSelector}
      />
    </Container>
  )
}

export const ReceptionND = props => (
  <EnsureGlobalOrderWrapper>
    <ReceptionNDBase {...props} />
  </EnsureGlobalOrderWrapper>
)