import React from 'react';
import { ForgotPasswordBase } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { ErrorBoundary } from '../../ui/ErrorBoundary';

export const ForgotPasswordSubBase = () => {

  return (
		<ForgotPasswordBase />
	)
}

export const ForgotPassword = props => (
	<ErrorBoundary>
		<ForgotPasswordSubBase {...props} />
	</ErrorBoundary>
)