import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";
import { refreshData } from './refreshData';

export const undispatchAll = ({
  globalorder_id,
  locale,
  producerproduct_id,
  setCurrentDispatch,
  createAlert,
  couloir,
  getGroupedDispatchProducts,
  excludedLocales
}) => () => {

  const caller = wrapClientApiCall({
    url: 'globalorders/undispatch-all-for-product',
  });

  return caller({
    params: {
      globalorder_id,
      locale,
      producerproduct_id,
      userId: '2W8X2SaMhytesy4ne',
      includeMergedLocales: getGroupedDispatchProducts,
      excludedLocales
    },
  })
    .then(refreshData({
      globalorder_id,
      locale,
      producerproduct_id,
      setCurrentDispatch,
      createAlert,
      couloir,
      getGroupedDispatchProducts,
      excludedLocales
    })
    )
    .catch((err) => createAlert({ message: err.message }));
};