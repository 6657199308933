import React from 'react';
import { useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import { PlanningDay } from '@kelbongoo/shared-client-react-v2/dist/components/PlanningDay';
import { ProductInfos } from '@kelbongoo/shared-client-react-v2/dist/components/ProductInfos';
import { EnsureGlobalOrderWrapper } from '@kelbongoo/shared-client-react-v2/dist/components/SharedPrepaDistribBase';
import { ErrorBoundary } from '../../ui/ErrorBoundary';
import selectors from '../../../selectors';
import { formatEvents } from './helpers';
import { Link } from 'react-router-dom';


const HomeBase = () => {

	const { list: productInfosList, loading: productInfosLoading } = useSelector(state => state.productinfos);
	const globalorderlocale = selectors.getCurrentGlobalOrderLocale();


	const tasks = [];

	return (
		<Container fluid style={{marginTop: "15px"}}>
			<h3>Pr&eacute;parations</h3>

			<Row className="mt-8 mb-8">
				<Col md={6} xs={12}>
					<Card>
						<Card.Header>Evenements du jour</Card.Header>
						<PlanningDay
							Link={Link}
							isHome={true}
							planning={false}
							events={formatEvents({ globalorderlocale, tasks })}
							ready={true}
						/>
					</Card>
				</Col>
				<Col md={6} xs={12}>
					<Card>
						<Card.Header>Infos produits</Card.Header>
						<ProductInfos
							ready={!productInfosLoading}
							infos={productInfosList}
						/>
					</Card>
				</Col>
			</Row>
		</Container>
	)
}

export const Home = props => (
	<ErrorBoundary>
		<EnsureGlobalOrderWrapper>
			<HomeBase {...props} />
		</EnsureGlobalOrderWrapper>
	</ErrorBoundary>
)