import { wrapClientApiCall } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall';

export const markArrived = ({
  retailrestock,
  setRetailRestock,
  createAlert,
  locale
}) => () => {

  const caller = wrapClientApiCall({
    url: 'boutiques/mark-boutiquerestock-arrived',
  })

  return caller({
    params: {
      boutique: locale,
      boutiquerestock_id: retailrestock._id,
    }
  })
    .then(({ arrivedAt, arrivedBy }) => {

      createAlert({ type: 'success' });

      retailrestock.arrivedAt = arrivedAt;
      retailrestock.arrivedBy = arrivedBy;
      setRetailRestock(retailrestock);
    })
    .catch(err => createAlert(err));
};