import React, { useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { LoginBase } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { fetchLocales } from '@kelbongoo/shared-client-react-v2/dist/components/SharedPrepaDistribBase/actions';
import { actions as sessionActions } from '../../../reducers/session';
import { actions as localesActions } from '../../../reducers/locales';
import { ErrorBoundary } from '../../ui/ErrorBoundary';

export const LoginSubBase = props => {

	const dispatch = useDispatch();
	const { locales } = useSelector(state => state.locales)

	useEffect(() => {
		if(!locales.length){
			dispatch(fetchLocales());
		}
	}, [dispatch, locales])

	const extraParams = [{
		name: 'locale',
		label: 'Local',
		placeholder: 'Choisir un local',
		required: true,
		options: locales && locales.map(locale => ({
			name: locale.name,
			value: locale.code
		}))
	}]
	const setExtraParams = ({ locale }) => dispatch(localesActions.setCurrentLocale(locale));
	const onLoginSuccess = () => dispatch(sessionActions.setInit(true));

  return (
		<LoginBase 
			extraParams={extraParams} 
			setExtraParams={setExtraParams} 
			onLoginSuccess={onLoginSuccess}
			Link={Link}
			Redirect={Redirect}
			dispatch={dispatch}
			useSelector={useSelector}
			{...props} 
		/>
	)
}

export const Login = props => (
	<ErrorBoundary>
		<LoginSubBase {...props} />
	</ErrorBoundary>
)