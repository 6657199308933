import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";
import { actions as goppsActions } from "../reducers/gopps";

export const fetchAllGopps = () => (dispatch, getState) => {
  const {
    globalorderlocales: { currentGlobalOrder: globalorder_id },
  } = getState();
  const { fetchGoppsFailed, fetchGoppsRequest, fetchGoppsSuccess } =
    goppsActions;

  const caller = wrapClientApiCall({
    url: "globalorderproducerproducts/fetch",
  });

  dispatch(fetchGoppsRequest());

  return caller({
    params: {
      globalorder_id,
    },
  })
    .then(({ items, count }) => {
      dispatch(fetchGoppsSuccess(items));
    })
    .catch((error) => {
      dispatch(fetchGoppsFailed(error.message || error.name));
    });
};
