export const tableColumns = [
  { title: 'Produit' },
  {
    title: 'Progrès',
    classes: 'text-center vertical-center'
  },
  {
    title: 'Fini',
    classes: 'text-center vertical-center',
    width: '60px',
  }
];