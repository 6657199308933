import React from 'react';
import PropTypes from 'prop-types';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import { useSelector, useDispatch } from 'react-redux';
import { TouchActivatedMethod } from '@kelbongoo/shared-client-react-v2/dist/components/TouchActivatedMethod';
import { HeaderContent } from './HeaderContent';
import { logoutUser, closeAlert } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase/actions';

const HomeButton = () =>
  <TouchActivatedMethod
    timeout={2000}
    callback={() => window && window.location.reload()}
  >
    <Nav.Item>
      <Nav.Link to="/" style={{ background: 'transparent' }}>
        <Image
          src="https://cdn.kelbongoo.com/images/tomate.svg"
          style={{ height: '36px', width: '36px' }}
          role='logo' />
      </Nav.Link>
    </Nav.Item>
  </TouchActivatedMethod>

const Alerts = () => {
  const dispatch = useDispatch();
  const { alerts } = useSelector(state => state.alerts);
  const handleCloseAlert = id => dispatch(closeAlert(id));

  if (alerts.length === 0) {
    return null;
  }

  return <div className="clearfix mb-3 mx-3" style={{ height: '52px' }}>
    <Alert
      variant={alerts[0].type === 'success' ? 'success' : 'danger'}
      dismissible={true}
      className="m-0"
      onClose={() => handleCloseAlert(alerts[0]._id)}
      role='alert'
    >
      <b className='mr-2'>{alerts[0].type === 'success' ? 'Succès !' : 'Attention !'}</b>
      {alerts[0].message} &nbsp;
      {alerts.length > 1 ? (<Badge variant='light'>{alerts.length}</Badge>) : null}
    </Alert>
  </div>;
}

export const HeaderBase = ({
  HeaderItemsLeft,
  HeaderItemsRight,
}) => {
  const dispatch = useDispatch();
  const {
    loggedIn,
    isLoggingOut
  } = useSelector(state => state.currentUser);
  const onLogoutUser = () => dispatch(logoutUser());

  return (
    <>
      <Nav
        as={'header'}
        defaultActiveKey='/'
        style={{
          borderBottom: '1px solid #d3d3d3',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <HomeButton />

        {loggedIn && (
          <HeaderContent
            onLogoutUser={onLogoutUser}
            HeaderItemsLeft={HeaderItemsLeft}
            HeaderItemsRight={HeaderItemsRight}
            isLoggingOut={isLoggingOut}
          />
        )}

      </Nav>

      <Alerts />
    </>
  )
};

HeaderBase.propTypes = {
  dispatch: PropTypes.func.isRequired,
  HeaderItemsLeft: PropTypes.elementType,
  HeaderItemsRight: PropTypes.elementType
}
