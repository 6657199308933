import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const adjustDispatchBac = ({
  globalorder_id,
  locale,
  producerproduct_id,
  couloir,
  setCurrentDispatch,
  createAlert,
  loadData
}) => ({ order_id, dispatch_id }) => ({ bac }) => {

  const params = {
    globalorder_id,
    locale,
    producerproduct_id,
    dispatch_id,
    order_id,
    bac,
    couloir,
    userId: '2W8X2SaMhytesy4ne'
  };

  const caller = wrapClientApiCall({
    url: 'globalorderlocales/adjust-dispatch-bac'
  })

  return caller({ params })
    .then(({ dispatch }) => {
      setCurrentDispatch(dispatch)
    })
    .catch((err) => createAlert({ message: err.message }))
    .finally(loadData);;
};