import * as sharedSelectors from '@kelbongoo/shared-client-react-v2/dist/components/SharedPrepaDistribBase/selectors';

const selectors = {
  ...sharedSelectors,
  getAllProducers(gopps){
    const result = gopps.reduce((a,g) => {
      let ind = a.findIndex(b => b.producer_id === g.producer_id)
      if(ind === -1){
        a.push({
          producer_name: g.producer_name,
          producer_id: g.producer_id
        })
      }
      return a;
    }, []);
    return result;
  }
}

export default selectors;