import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const refreshData = ({
  globalorder_id,
  locale,
  producerproduct_id,
  setCurrentDispatch,
  createAlert,
  couloir,
  getGroupedDispatchProducts,
  excludedLocales
}) => () => {

  const caller = wrapClientApiCall({
    url: 'globalorders/get-dispatch',
  });

  return caller({
    params: {
      globalorder_id,
      locale,
      producerproduct_id,
      couloir,
      user_id: '2W8X2SaMhytesy4ne',
      filterUnsold: true,
      getGroupedDispatchProducts, // FIXME: Remove this too.
      excludedLocales
    },
  })
    .then(({ dispatch }) => {
      setCurrentDispatch(dispatch)
    })
    .catch((err) => createAlert({ message: err.message }));
};