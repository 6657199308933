import React from 'react';
import { useSelector } from 'react-redux';
import Badge from 'react-bootstrap/Badge';
import { getVariantFromLocale } from '../../../helpers/getVariantFromLocale';

export const LocaleBadgeList = () => {
  const { currentLocale } = useSelector((state) => state.locales);
  const { groupeddispatchlocales: locales } = useSelector(
    (state) => state.groupeddispatchlocales
  );
  return <div style={{ display: 'inline-block' }} className="text-xl">
    {(locales &&
      locales.includes(currentLocale))
      ? (
        locales.map((locale) => (
          <Badge
            className="m-1"
            variant={getVariantFromLocale(locale)}
            key={locale}
          >
            {locale}
          </Badge>
        ))
      ) : (
        <span>Chargement en cours...</span>
      )}
  </div>;
};
