import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container';
import { createAlert as createAlertBase } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import {
  Datatable,
  updateQuery as updateQueryBase,
  refreshData as refreshDataBase
} from '@kelbongoo/shared-client-react-v2/dist/components/Datatable';
import { EnsureGlobalOrderWrapper } from '@kelbongoo/shared-client-react-v2/dist/components/SharedPrepaDistribBase';
import { ErrorBoundary } from '../../ui/ErrorBoundary';
import { RedirectionsHomeRow } from './RedirectionsHomeRow';
import { buildFilters } from './helpers';


export const RedirectionsHomeBase = () => {


  const dispatch = useDispatch();
  const { currentLocale: locale, locales } = useSelector(state => state.locales);
  const { currentGlobalOrder: globalorder_id } = useSelector(state => state.globalorderlocales);

  const [items, setItems] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [docsPerPage, setDocsPerPage] = useState(10);
  const [currentQuery, setCurrentQuery] = useState({});
  const [page, setPage] = useState(1);
  const createAlert = (...args) => dispatch(createAlertBase(...args))
  const { selectFilters, searchFilter, quickFilter } = buildFilters({ locales })
  const updateQuery = updateQueryBase({ setCurrentQuery, setPage, currentQuery })
  const refreshData = refreshDataBase({
    url: 'globalorderredirections/fetch',
    setItems,
    setCount,
    createAlert,
    setLoading,
    selectorAddons: { globalorder_id, locale },
    sortFields: { createdAt: -1 }
  })
  useEffect(() => {
    refreshData({
      currentQuery,
      page,
      docsPerPage
    })
  }, [currentQuery, page, docsPerPage]);

  return (
    <Container fluid>
      <Datatable
        DatatableRow={RedirectionsHomeRow}
        items={items}
        count={count}
        loading={loading}
        updateQuery={updateQuery}
        setDocsPerPage={setDocsPerPage}
        columns={[]}
        selectFilters={selectFilters}
        searchFilter={searchFilter}
        quickFilter={quickFilter}
        tableName={`redirections-home`}
      />
    </Container>
  )
}

export const RedirectionsHome = props => (
  <ErrorBoundary>
    <EnsureGlobalOrderWrapper>
      <RedirectionsHomeBase {...props} />
    </EnsureGlobalOrderWrapper>
  </ErrorBoundary>
)