import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";
import { transferDispatch } from '../helpers';

export const dispatchOneFromScan = ({
  globalorder_id,
  producerproduct_id,
  createAlert,
  loadData,
  gopp,
  bacsDone,
  currentDispatch,
  setGopp,
  setCurrentDispatch,
  setBacsDone,
}) => (code) => {
  const caller = wrapClientApiCall({
    url: 'globalorderlocaleproducerproducts/dispatch-one-from-scan',
  })

  return caller({
    params: {
      code,
      globalorder_id,
      producerproduct_id,
      userId: '2W8X2SaMhytesy4ne'
    },
  })
    .then(({ dispatchqueue }) => {
      let transfer = transferDispatch({
        currentDispatch,
        gopp,
        dispatch_id: dispatchqueue._id,
        bacsDone,
      });
      if (transfer.gopp.completed) transfer.gopp.locale_progress.map(v => v.completed = transfer.gopp.completed);
      setGopp(transfer.gopp);
      setCurrentDispatch(transfer.currentDispatch);
      setBacsDone(transfer.bacsDone);
    })
    .catch((error) => {
      createAlert({ message: error.message });
    })
    .finally(loadData);
};