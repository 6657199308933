import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const adjustDispatchWeight = ({ 
  globalorder_id, 
  locale, 
  producerproduct_id, 
  couloir, 
  currentDispatch,
  setCurrentDispatch,
  createAlert,
  loadData
}) => ({ order_id, dispatch_id }) => ({ weight }) => {

  const caller = wrapClientApiCall({
    url: 'globalorderlocales/update-order-item-weight',
  })

  return caller({
    params: {
      order_id,
      weight,
      couloir,
      dispatch_id,
      globalorder_id,
      locale,
      producerproduct_id,
      userId: '2W8X2SaMhytesy4ne'
    },
  })
    .then(() => {
      let ind = currentDispatch.findIndex(
        (d) => d.items.includes(dispatch_id)
      );
      currentDispatch[ind].adjustment = true;
      setCurrentDispatch(currentDispatch);
    })
    .catch((err) => createAlert({ message: err.message }))
    .finally(loadData);
};