import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const toggleCompleted = ({
  globalorder_id,
  createAlert,
  refreshData,
  locale,
  excludedLocales
}) => (producerproduct_id, completed) => () => {
  const caller = wrapClientApiCall({
    url: 'globalorders/toggle-golpps-completed',
  });

  return caller({
    params: {
      globalorder_id,
      locale,
      producerproduct_id,
      userId: '2W8X2SaMhytesy4ne',
      completed,
      excludedLocales,
      isND: false
    }
  }).then(() => refreshData())
    .catch((err) => createAlert({ message: err.message }));
};