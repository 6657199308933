import React from 'react';
import { Link } from 'react-router-dom';
import { run as formatDate } from '@kelbongoo/shared-methods/utils/formatDate';
import { Icon } from '@kelbongoo/shared-client-react-v2/dist/components/Icon';


export const RedirectionsHomeRow = ({item}) => {

  const {
    currentLocale,
    locale_source,
    locale_destination,
    preparedAt,
    receivedAt,
    _id,
    direction,
    items = []
  } = item;

  const statusTitle = (
    <>
      {currentLocale === locale_source ? (
        <i>Ici</i>
      ) : (
        <>locale_source</>
      )} 
      <Icon name='arrow-right'  />
      {currentLocale === locale_destination ? (
        <i>Ici</i>
      ) : (
        <>{locale_destination}</>
      )}
    </>
  )

  return (
    <tr>
      <td>
        <Link className='bold' to={`/redirections/${_id}`}>
          {direction}
        </Link>
        {preparedAt && (
          <div className='italic text-muted text-sm'>
            Pr&eacute;paration
            <Icon name='check' className='text-success' />
            {formatDate(preparedAt, 'HH:mm')}
          </div>
        )}
        {receivedAt && (
          <div className='italic text-muted text-sm'>
            Reception
            <Icon name='check' className='text-success' />
            {formatDate(receivedAt, 'HH:mm')}
          </div>
        )}
      </td>
      <td className='text-center vertical-center' style={{width : '80px'}}>
        {items.length}
      </td>
      <td className='text-center vertical-center bold' style={{width : '120px'}}>
        <div dangerouslySetInnerHTML={{ __html: statusTitle }} />
      </td>
    </tr>
  )
}