import { createSlice } from '@reduxjs/toolkit';
import { goppsInitialState, goppsReducers } from '@kelbongoo/shared-client-react-v2/dist/components/SharedPrepaDistribBase';

const { reducer, actions } = createSlice({
  name: 'gopps',
  initialState: goppsInitialState,
  reducers: {
    ...goppsReducers,
    fetchLocaleListRequest(state){
      state.loading = true;
      state.error = undefined;
    },
    fetchLocaleListSuccess(state, action){
      const gopps = state.gopps || [];
      const items = action.payload || [];
      
      for(let item of items){
        let gopp = gopps.find(
          g => g.producerproduct_id === item.producerproduct_id
        )
        if(!gopp){
          continue; // hmm...
        }
        gopp = Object.assign(gopp, item);
      }
      state.loading = false;
    },
    fetchLocaleListFailed(state, action){
      state.loading = true;
      state.error = action.payload;
    }
  }
});

export { reducer, actions };