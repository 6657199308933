import { createSlice } from '@reduxjs/toolkit';
import { sessionInitialState, sessionReducers } from '@kelbongoo/shared-client-react-v2/dist/components/SharedPrepaDistribBase';

const { reducer, actions } = createSlice({
  name: 'session',
  initialState: {
    ...sessionInitialState,
    currentRole: 'home'
  },
  reducers: {
    ...sessionReducers,
    // allow persistent datatable queries
    persistDatatableParams(state, action){
      const [key] = Object.keys(action.payload);
      state[key] = action.payload[key];
    }
  }
});

export { reducer, actions };