import { wrapClientApiCall } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall';
import cloneDeep from 'lodash.clonedeep';

export const undoAdjustDispatchWeight = ({ 
  globalorder_id, 
  locale, 
  producerproduct_id, 
  couloir, 
  currentDispatch,
  setCurrentDispatch,
  createAlert
}) => ({ order_id, dispatch_id }) => () => {

  const caller = wrapClientApiCall({
    url: 'globalorderlocales/undo-update-order-item-weight',
  })

  return caller({
    params: {
      order_id,
      couloir,
      dispatch_id,
      globalorder_id,
      locale,
      producerproduct_id,
      userId: '2W8X2SaMhytesy4ne'
    },
  })
    .then(() => {
      const updatedCurrentDispatch = cloneDeep(currentDispatch);
      let ind = updatedCurrentDispatch.findIndex(
        (d) => d.items.includes(dispatch_id)
      );
      delete updatedCurrentDispatch[ind].adjustment;
      setCurrentDispatch(updatedCurrentDispatch);
    })
    .catch((err) => {
      createAlert({ message: err.message })
    });
};